import { FC, useEffect, useState } from 'react';

import { SearchProviderParams } from '@partoohub/api-client';
import { Option, Section, SingleSelect, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useProviders } from 'admin/common/hooks/queries/useProviders';
import useDebounce from 'admin/common/hooks/useDebounce';

import { convertProviderToSelectOption } from './convertProviderToOption';

import { EmptyMessageWrapper } from '../EmptyMessage';

type Props = {
    provider?: Option;
    setProvider: (selectedItems?: Option) => void;
    dataTrackId: string;
    queryParams?: SearchProviderParams;
};

export const NewProviderSelect: FC<Props> = ({
    provider,
    setProvider,
    dataTrackId,
    queryParams,
}: Props) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [section, setSection] = useState<Section[]>([]);

    const debouncedSearchValue = useDebounce(searchValue);

    const queryParamsProviderSearch: SearchProviderParams = {
        query: debouncedSearchValue,
        ...queryParams,
    };

    const [{ data, fetchNextPage, hasNextPage, isLoading }] = useProviders(
        queryParamsProviderSearch,
        {
            keepPreviousData: true,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    );

    const providers = data?.pages?.map(({ providers }) => providers)?.flat();

    useEffect(() => {
        if (!isLoading) {
            const options =
                providers?.map(provider => convertProviderToSelectOption(provider)) || [];
            const section = [{ options: options }];
            setSection(section);
        }
    }, [data]);

    return (
        <SingleSelect
            dataTrackId={dataTrackId}
            sections={section}
            selectedValue={provider}
            onChange={setProvider}
            onSearch={setSearchValue}
            label={t('admin:provider')}
            placeholder=""
            maxHeight={300}
            loadMore={fetchNextPage}
            hasMore={hasNextPage}
            emptyMessage={
                <EmptyMessageWrapper>
                    <Text variant="bodyMMedium">
                        {t('admin:select_organization__empty_message')} “{debouncedSearchValue}“
                    </Text>
                </EmptyMessageWrapper>
            }
        />
    );
};
