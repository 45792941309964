import styled from '@emotion/styled';

export const RemapInformationContainer = styled.div`
    background: ${({ theme }) => theme.colors.theme.background};
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px;
    width: 100%;
    margin-bottom: 31px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const RemapInformationSection = styled.div`
    width: 50%;
`;

export const RemapInformationIcon = styled.div`
    display: inline-block;
    margin-right: 16px;
    width: 56px;
    img {
        display: block;
    }
`;

export const RemapInformationDetails = styled.div`
    display: inline-block;
    margin-right: 16px;
    vertical-align: top;
`;
