import { css } from '@emotion/react';
import styled from '@emotion/styled';

type OptionWrapperProps = {
    selected: boolean;
};

export const SwitchInput = styled.div`
    display: flex;
    gap: 4px;

    border-radius: 9px;
    padding: 8px;

    max-width: fit-content !important;

    background-color: #f6f8fc;
`;

export const OptionWrapper = styled.div<OptionWrapperProps>`
    display: flex;
    align-items: center;

    box-shadow: none;
    border-radius: 9px;
    padding: 8px 16px;

    color: ${({ theme }) => theme.colors.secondary.initial};
    fill: ${({ theme }) => theme.colors.secondary.initial};
    font-weight: 600;
    line-height: 18px;

    cursor: pointer;
    user-select: none;

    transition:
        color 0.2s,
        background-color 0.2s,
        fill 0.2s;

    &:hover {
        color: ${({ theme }) => theme.colors.default.initial};
        fill: ${({ theme }) => theme.colors.default.initial};
    }

    ${({ selected, theme }) =>
        selected &&
        css`
            box-shadow: ${theme.shadows.initial};
            background-color: ${theme.colors.theme.initial};
            color: ${theme.colors.default.initial};
            fill: ${theme.colors.default.initial};
        `}
`;

export const IconWrapper = styled.div`
    margin-right: 4px;

    height: 24px;
    width: 24px;
`;
