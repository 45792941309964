import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const DiffusionRowStatus = styled.span`
    border-radius: 4px 0px 0px 4px;
    width: 5px;
    align-self: stretch;
`;

export const DiffusionRowContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    border-radius: 0 4px 4px 0;
    flex: 1;
    max-width: 100%;
`;

const getAppearanceStyle = (appearance?: string) => {
    if (appearance === 'outdated') {
        return css`
            opacity: 0.25;
        `;
    }
    if (appearance === 'content') {
        return css`
            justify-content: space-between;
            width: 100%;
            max-width: 100%;
            margin: 0px;
            background-color: transparent;
            height: 80px;
        `;
    }
    if (appearance === 'full') {
        return css`
            margin-bottom: 8px;
            margin-top: 4px !important;
        `;
    }
    return css``;
};
export const DiffusionTableRowContainer = styled.div<{ appearance: string }>`
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.theme.initial};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 4px !important;
    ${({ appearance }) => getAppearanceStyle(appearance)};
`;
