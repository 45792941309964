import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, convertToRgba, greyColorObject } from '@partoohub/branding';
import { LOW_PRIORITY_COMPONENT_Z_INDEX, OVER_APP_DRAWER_Z_INDEX } from '@partoohub/ui';
interface MenuCardProps {
    maxHeight: number;
}

export const MenuCard = styled.div<MenuCardProps>`
    box-shadow:
        0 4px 4px ${convertToRgba(HEX_COLORS.black, 0.15)},
        0 2px 10px ${convertToRgba(HEX_COLORS.black, 0.15)};
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.theme.background};
    box-sizing: border-box;
    transform-origin: top;
    padding: 4px;
    display: flex;
    flex-direction: column;

    overflow-y: auto; // will be modified by FlipSizeAnimated
    max-height: ${props => props.maxHeight}px;

    will-change: height;

    z-index: ${OVER_APP_DRAWER_Z_INDEX};
`;

export const SectionCard = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SectionCardHeader = styled.div`
    height: 38px;
    display: flex;
    align-items: center;
    padding-left: 8px;

    color: ${({ theme }) => theme.colors.default.initial};
    font-weight: 700;
    font-size: 14px;
    border-radius: 4px;

    cursor: pointer;

    background-color: ${({ theme }) => theme.colors.theme.background};
    z-index: ${LOW_PRIORITY_COMPONENT_Z_INDEX};

    &:hover {
        background-color: ${greyColorObject.light};
    }
`;

interface IconProps {
    direction: 'down' | 'right';
}

export const Icon = styled.div<IconProps>`
    margin-left: auto;
    margin-right: 12px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    ${props =>
        props.direction === 'down' &&
        css`
            transform: rotate(90deg);
        `}
    transition: transform .3s linear;
`;

interface SectionCardBodyProps {
    opened: boolean;
}

export const RelativeContainer = styled.div`
    position: relative;
`;

interface ContainerProps {
    position?: 'absolute' | 'relative' | 'static';
}

export const Container = styled.div<ContainerProps>`
    position: ${props => props.position || 'static'};
    background-color: ${({ theme }) => theme.colors.theme.background};
`;

export const ItemCard = styled.div`
    height: 38px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    border-radius: 4px;

    color: ${({ theme }) => theme.colors.default.initial};
    font-weight: 400;
    font-size: 14px;

    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.default.alpha};
    }
`;

export const EmptyCard = styled.div`
    height: 38px;
    display: flex;
    align-items: center;
    margin-left: 8px;

    color: ${({ theme }) => theme.colors.secondary.initial};
    font-weight: 400;
    font-size: 14px;
`;

export const SectionCardBody = styled.div<SectionCardBodyProps>`
    // not using flex-box, neither gap to position ItemCards because
    // the user may add other containers in between
    & ${ItemCard} {
        margin: 4px 0;
    }

    & ${ItemCard}:first-of-type {
        margin: 0 0 4px 0;
    }

    & ${ItemCard}:last-child {
        margin: 4px 0 0 0;
    }

    & ${ItemCard}:only-child {
        margin: 0;
    }

    opacity: 0;
    will-change: opacity;
    transition:
        opacity 0.3s linear,
        background-color 0.3s linear;

    &.fade-exit-active,
    &.fade-exit-done {
        opacity: 0;
    }

    &.fade-enter-active,
    &.fade-enter-done {
        opacity: 1;
    }
`;
