import { useLocation, useNavigate } from 'react-router';

export const useFromPage = (withoutQuerySearch?: boolean) => {
    const location = useLocation();
    const navigate = useNavigate();
    const goBack = () => {
        const searchParams = location.state?.from ? new URLSearchParams(location.state.from) : null;
        if (withoutQuerySearch) {
            searchParams?.delete('search_query');
        }
        const goBackUrl = searchParams ? `/categories?${searchParams.toString()}` : '/categories';
        navigate(goBackUrl);
    };
    return {
        goBack,
    };
};
