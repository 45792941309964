import { useEffect, useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import {
    Button,
    FontAwesomeIconsPartooUsed,
    MultiSelect,
    Option,
    Text,
    TextInput,
} from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

import { CompetitorWithId } from 'admin/common/api/types/competitiveBenchmark';

import { ConfirmWarningModal } from './ConfirmWarningModal/ConfirmWarningModal';
import { Footer, HeaderText, LeftSideContent } from './EditCompetitorModal.styled';
import { useCreateNewCompetitor } from '../hooks/useCreateNewCompetitor';
import { useUpdateCompetitor } from '../hooks/useUpdateCompetitor';

type EditCompetitorModalProps = {
    onClose: () => void;
    opened: boolean;
    competitor?: CompetitorWithId;
    keywordId: string;
};

type Props = {
    onSave: () => void;
    competitor?: CompetitorWithId;
    keywordId: string;
    setHaveChanges: (haveChanges: boolean) => void;
};

const stringsToOptions = (strings: string[]): Option[] => {
    return strings.map(item => {
        return {
            label: item,
            value: item,
            name: item,
        };
    });
};

const EditCompetitorContent = ({ competitor, keywordId, onSave, setHaveChanges }: Props) => {
    const { t } = useTranslation();
    const [isNameErrorNotice, setIsNameErrorNotice] = useState<string | undefined>(undefined);
    const {
        mutate: updateCompetitor,
        isSuccess: isUpdateSuccess,
        isError: isUpdateError,
        reset: resetUpdate,
    } = useUpdateCompetitor();
    const {
        mutate: createCompetitor,
        isSuccess: isCreateSuccess,
        isError: isCreateError,
        reset: resetCreate,
    } = useCreateNewCompetitor();

    const [name, setName] = useState(competitor?.name || '');
    const [included, setIncluded] = useState(stringsToOptions(competitor?.included || []));
    const [excluded, setExcluded] = useState(stringsToOptions(competitor?.excluded || []));

    useEffect(() => {
        if (isUpdateSuccess) {
            onSave();
            resetUpdate();
        }
        setIsNameErrorNotice(undefined);
    }, [isUpdateSuccess]);

    useEffect(() => {
        if (isCreateSuccess) {
            onSave();
            resetCreate();
        }
        setIsNameErrorNotice(undefined);
    }, [isCreateSuccess]);

    useEffect(() => {
        if (isUpdateError || isCreateError) {
            setIsNameErrorNotice(
                t('admin:competitive_benchmark__modal_create_competitor_error_notice'),
            );
        }
    }, [isUpdateError, isCreateError]);

    useEffect(() => {
        setIsNameErrorNotice(undefined);
    }, [name]);

    useEffect(() => {
        setHaveChanges(
            (!!competitor &&
                (name !== competitor?.name ||
                    included.length !== competitor.included.length ||
                    excluded.length !== competitor.excluded.length ||
                    !included.every(item => competitor.included.includes(item.value)) ||
                    !excluded.every(item => competitor.excluded.includes(item.value)))) ||
                (!competitor && (name !== '' || included.length !== 0 || excluded.length !== 0)),
        );
    }, [name, included, excluded, competitor]);

    const handleSubmit = () => {
        const updatedCompetitor = {
            ...competitor,
            name,
            included: included.map(item => item.value),
            excluded: excluded.map(item => item.value),
        };
        if (updatedCompetitor.id !== undefined) {
            updateCompetitor({ competitor: updatedCompetitor as CompetitorWithId });
        } else {
            createCompetitor({ ...updatedCompetitor, keywordId });
        }
    };

    const creatableProps = {
        suggestionProps: {
            label: t('admin:create_keyword'),
        },
    };

    return (
        <>
            <LeftSideContent>
                <HeaderText as="span" variant="heading2">
                    {competitor?.name}
                </HeaderText>
                <TextInput
                    label={t('admin:competitor_name')}
                    dataTrackId={t('admin:competitor_name')}
                    value={name}
                    onChange={value => setName(value)}
                    required
                    notice={isNameErrorNotice}
                    error={!!isNameErrorNotice}
                />
                <Text as="span" variant="heading6">
                    {t('admin:edit_competitor_modal_subtitle')}
                </Text>
                <MultiSelect
                    creatableProps={creatableProps}
                    dataTrackId={t('admin:edit_competitor_modal_include')}
                    label={t('admin:edit_competitor_modal_include')}
                    selectedValue={included}
                    sections={[{ options: [] }]}
                    onChange={setIncluded}
                    max={8}
                />
                <MultiSelect
                    creatableProps={creatableProps}
                    dataTrackId={t('admin:edit_competitor_modal_exclude')}
                    label={t('admin:edit_competitor_modal_exclude')}
                    selectedValue={excluded}
                    sections={[{ options: [] }]}
                    onChange={setExcluded}
                    max={8}
                />
            </LeftSideContent>
            <Footer>
                <Button
                    full
                    disabled={!name}
                    isLoading={false}
                    dataTrackId={''}
                    onClick={handleSubmit}
                    size="large"
                    icon={[FontAwesomeIconsPartooUsed.faSave]}
                >
                    {t('save')}
                </Button>
            </Footer>
        </>
    );
};

export const EditCompetitorModal = ({
    onClose,
    opened,
    competitor,
    keywordId,
}: EditCompetitorModalProps) => {
    const { t } = useTranslation();
    const [haveChanges, setHaveChanges] = useState(false);
    const [openWarningModal, setOpenWarningModal] = useState(false);
    const childrenRight = (
        <EditFormDescription
            title={t('admin:edit_competitor_modal_help_title')}
            description={t('admin:edit_competitor_modal_help_description')}
            advice={t('admin:edit_competitor_modal_help_advice')}
        />
    );

    const handleOnClose = () => {
        if (haveChanges) {
            setOpenWarningModal(true);
        } else {
            onClose();
        }
    };

    const illustrationElement: IllustrationElement = {
        type: IllustrationCategoryEnum.Old,
        name: IllustrationOldEnum.Danger,
    };

    return (
        <>
            <FullScreenModal
                onHideClick={handleOnClose}
                show={opened}
                childrenRight={childrenRight}
                childrenLeft={
                    <EditCompetitorContent
                        competitor={competitor}
                        keywordId={keywordId}
                        onSave={onClose}
                        setHaveChanges={setHaveChanges}
                    />
                }
            />
            <ConfirmWarningModal
                opened={openWarningModal}
                onConfirm={onClose}
                onClose={() => setOpenWarningModal(false)}
                title={t('admin:unsaved_changes_title')}
                subTitle={t('admin:unsaved_changes_subtitle')}
                asset={illustrationElement}
                confirmText={t('admin:leave')}
            />
        </>
    );
};
