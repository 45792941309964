import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const Footer = styled.div`
    position: sticky;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.theme.initial};
    border-top: 1px solid ${({ theme }) => theme.colors.default.alpha};

    margin-top: auto;
    padding: 16px;
`;

export const HeaderText = styled(Text)`
    margin-bottom: 8px;
    text-transform: capitalize;
`;

export const LeftSideContent = styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;
