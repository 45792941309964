import { FC, useState } from 'react';

import { Option, SingleSelect, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { FEATURE_ENUM } from 'app/api/types/plans_features';
import { DemoCreatorAdminApiCall } from 'app/api/v2/api_calls/demoCreatorApiCalls';

import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import { languagesData } from 'admin/common/data/languages';

const DemoCreator: FC = () => {
    const { t } = useTranslation();

    const langOptions: Option[] = languagesData
        .filter(({ supportedForDemo }) => supportedForDemo)
        .map(({ code }) => ({
            label: t(`original-${code}`),
            name: code,
            value: code,
        }));
    const sections = [{ options: langOptions }];

    const [org, setOrg] = useState<Option | undefined>(undefined);
    const [lang, setLang] = useState<Option | undefined>(undefined);

    const { mutateAsync, isLoading } = useMutation(DemoCreatorAdminApiCall.createDemo, {
        onSuccess: (data: any) => {
            if (data.status === 'success' || data.status === 'pending') {
                toast.success(
                    t('admin:page_demo_creator__success_toast__content'),
                    t('admin:page_demo_creator__success_toast__title'),
                );
            } else toast.error(data.error, t('admin:toast_error__title'));
        },
        onError: (error: any) => {
            toast.error(
                error.response?.data?.errors?.json?.error || error.message,
                t('admin:toast_error__title'),
            );
        },
        onSettled: () => resetForm(),
    });

    const resetForm = () => {
        setOrg(undefined);
        setLang(undefined);
    };

    const submitForm = async () => {
        await mutateAsync({
            org: org?.value || '',
            lang: lang?.value || '',
        });
    };

    const isFormValid = () => !!org && !!lang;
    return (
        <AdminPage
            dataTrackId="demo_creator__page"
            title={t('admin:page_demo_creator__title')}
            description={t('admin:page_demo_creator__description')}
            infoLink="https://partoo.elium.com/tile/view/1549"
        >
            <BorderedContainer>
                <PageFormRow>
                    <NewOrganizationSelect
                        dataTrackId="demo-creator-organization"
                        orgs={org}
                        setOrgs={setOrg}
                        queryParams={{
                            has_feature: FEATURE_ENUM.DEMO_CREATOR,
                        }}
                        placeholder={t('admin:organization')}
                    />
                    <SingleSelect
                        dataTrackId="admin_demo_creator__select_language"
                        sections={sections}
                        selectedValue={lang}
                        onChange={setLang}
                        label={t('admin:page_demo_creator__select_language')}
                        maxHeight={300}
                        required
                    />
                </PageFormRow>
            </BorderedContainer>
            <SubmitButton
                dataTrackId="admin_demo_creator__create"
                disabled={!isFormValid() || isLoading}
                text={t('admin:page_demo_creator__button_create')}
                onClick={submitForm}
            />
        </AdminPage>
    );
};

export default DemoCreator;
