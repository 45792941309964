import { getQueryObjectFromObject } from '@partoohub/utils';

import { SuccessResponse } from 'app/api/types';
import { InteractionTagSearchType, InteractionTagType } from 'app/api/types/interactionTag';
import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

const RESOURCE_URI = 'interaction_tags';

class InteractionTag extends ApiResourceBaseClass {
    static async getInteractionTags(
        label = '',
        page = 1,
        perPage?: number,
    ): Promise<InteractionTagSearchType> {
        const params = label ? getQueryObjectFromObject({ label }) : {};
        params.page = page;
        if (perPage) {
            params.per_page = perPage;
        }

        const { data } = await InteractionTag.get(RESOURCE_URI, params);
        return data;
    }

    static async create(payload: Pick<InteractionTagType, 'label'>): Promise<InteractionTagType> {
        const { data } = await InteractionTag.post(RESOURCE_URI, payload);
        return data;
    }

    static async update(tagId: number, payload: Omit<InteractionTagType, 'id' | 'color'>) {
        const { data } = await InteractionTag.put(`${RESOURCE_URI}/${tagId}`, payload);
        return data;
    }

    static async deleteTag(tagId: number): Promise<SuccessResponse> {
        const { data } = await InteractionTag.delete(`${RESOURCE_URI}/${tagId}`);
        return data;
    }
}

export default InteractionTag;
