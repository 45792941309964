import { FC, useEffect, useState } from 'react';

import {
    Button,
    Chip,
    FontAwesomeIconsPartooUsed,
    Icon,
    IconPrefix,
    Option,
    Stack,
    toast,
} from '@partoohub/ui';
import qrcode from 'qrcode';
import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';

import { V2ConnectionToken } from 'app/api/types/connection';
import { RoleValue } from 'app/api/types/user';
import Connection from 'app/api/v2/api_calls/connectionApiCalls';

import { EllipsisLink } from 'admin/common/components/buttons/EllipsisLink';
import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import { NewRoleSelect } from 'admin/common/components/inputs/RoleSelect/NewRoleSelect';
import { NewUserSelect } from 'admin/common/components/inputs/UserSelect/NewUserSelect';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import { CONNECTION_PATH } from 'admin/common/data/routeIds';
import { getSelectedOptions } from 'admin/common/utils/selectOptions';

import { IncognitoToggle } from './components/IncognitoToggle';
import { PlatformTargetSwitch } from './components/PlatformTargetSwitch';
import {
    ButtonWrapper,
    Card,
    CardBoldText,
    CardLightText,
    QrCodeSide,
    QrCodeTextContainer,
    QrCodeWrapper,
    UrlSide,
    UrlWrapper,
} from './ConnectAs.styled';
import { useUser } from './hooks/useUser';

declare global {
    interface Window {
        partoo_extension: boolean;
    }
}

const ConnectAs: FC = () => {
    const { t } = useTranslation();
    const hasExtension = window.partoo_extension;
    const [org, setOrg] = useState<Option | undefined>(undefined);
    const [role, setRole] = useState<Option | undefined>(undefined);
    const [user, setUser] = useUser();
    const [platformTarget, setPlatformTarget] = useState<string>('desktop');
    const [connectionToken, setConnectionToken] = useState<string | null>(null);
    const [openIncognito, setOpenIncognito] = useState<boolean>(hasExtension);
    const [qrcodeData, setQrcodeData] = useState('');

    const selectedRole = role?.value;

    const generateConnectionTokenMutation = useMutation(
        (myUser: Option) => Connection.generateConnectionToken(myUser.value, 60),
        {
            onError: () => {
                toast.error(
                    t('admin:page_connect_as__error_toast__description'),
                    t('admin:page_connect_as__error_toast__title'),
                );
            },
            onSuccess: (data: V2ConnectionToken) => setConnectionToken(data.token),
        },
    );

    useEffect(() => {
        if (org) setUser(undefined);
    }, [org]);

    useEffect(() => {
        if (selectedRole) setUser(undefined);
    }, [selectedRole]);

    useEffect(() => {
        if (user) generateConnectionTokenMutation.mutate(user);
        else setConnectionToken('');
    }, [user]);

    useEffect(() => {
        if (platformTarget === 'desktop') {
            setQrcodeData('');
            return;
        }
        qrcode
            .toDataURL(generateConnectionLink(), {
                errorCorrectionLevel: 'L',
                margin: 0,
            })
            .then(setQrcodeData);
    }, [connectionToken, platformTarget]);

    const isFormValid = !!connectionToken;

    const onRefreshLink = () => {
        if (user) generateConnectionTokenMutation.mutate(user);
    };

    const resetForm = () => {
        setTimeout(() => {
            setOrg(undefined);
            setRole(undefined);
            setUser(undefined);
            setConnectionToken(null);
            setOpenIncognito(hasExtension);
        });
    };

    const generateConnectionLink = (): string => {
        const cleanHost = window.location.host.replace('admin.', 'app.');

        if (!connectionToken) return '';

        if (platformTarget === 'mobile')
            return encodeURI(`partoo://connect-as-mobile/${connectionToken}`);
        return encodeURI(
            `https://${cleanHost}${CONNECTION_PATH}?connection_token=${connectionToken}`,
        );
    };

    const onChipClick = () => {
        if (isFormValid) {
            navigator.clipboard.writeText(generateConnectionLink());
            toast.success(
                t('admin:page_connect_as__success_toast__content'),
                t('admin:page_connect_as__success_toast__title'),
            );
            resetForm();
        }
    };

    const copyChip = (
        <div
            data-track={`admin_connect_as__copy_link__${platformTarget}`}
            data-intercom-targert={`admin_connect_as__copy_link__${platformTarget}`}
        >
            <Chip
                dataTrackId={`admin_connect_as__copy_link__${platformTarget}__chip`}
                disabled={!isFormValid}
                onClick={onChipClick}
            >
                <Stack gap="4px" direction="row" alignItems="center">
                    <Icon
                        icon={[FontAwesomeIconsPartooUsed.faCopy, IconPrefix.SOLID]}
                        iconSize="12px"
                        color="primary"
                    />
                    {t('admin:copy')}
                </Stack>
            </Chip>
        </div>
    );

    const buttonLink = () =>
        connectionToken && platformTarget === 'desktop'
            ? {
                  link: generateConnectionLink(),
                  target: '_blank',
              }
            : undefined;

    const onSubmit = () => {
        resetForm();
        const url = buttonLink()?.link;
        if (openIncognito && url) {
            window.postMessage({
                type: 'partooExtensionMessage',
                action: 'chrome.windows.create',
                payload: { incognito: true, url },
            });
        }
    };

    return (
        <AdminPage
            dataTrackId="page_connect_as"
            title={t('admin:page_connect_as__title')}
            description={t('admin:page_connect_as__description')}
            infoLink={'https://partoo.elium.com/tile/view/274'}
        >
            <BorderedContainer>
                <PageFormRow>
                    <NewOrganizationSelect
                        dataTrackId="admin_connect_as__filter_organization"
                        orgs={org}
                        setOrgs={setOrg}
                        placeholder={t('admin:organization')}
                    />
                    <NewRoleSelect
                        role={role}
                        setRole={setRole}
                        placeholder={t('admin:role')}
                        dataTrackId="admin_connect_as__filter_role"
                        filterRole={roleMapped => roleMapped !== 'ADMIN'}
                    />
                </PageFormRow>
                <PageFormRow>
                    <NewUserSelect
                        dataTrackId="admin_connect_as__select_user"
                        user={user}
                        setUser={setUser}
                        placeholder={t('admin:user')}
                        searchParams={{
                            org_id: org?.value ? parseInt(org?.value) : undefined,
                            role: selectedRole as RoleValue,
                        }}
                        filterUser={userMapped => userMapped.role !== 'ADMIN'}
                        required
                    />
                </PageFormRow>
                {user && (
                    <>
                        <PageFormRow>
                            <PlatformTargetSwitch
                                value={platformTarget}
                                setValue={setPlatformTarget}
                            ></PlatformTargetSwitch>
                            <ButtonWrapper>
                                <Button
                                    dataTrackId={`admin_connect_as__refresh_link__${platformTarget}`}
                                    appearance="outlined"
                                    variant="primary"
                                    icon={[FontAwesomeIconsPartooUsed.faArrowsRotate]}
                                    onClick={onRefreshLink}
                                    disabled={platformTarget !== 'mobile'}
                                    tooltip={t('admin:page_connect_as__refresh_link_tooltip')}
                                >
                                    {t('admin:page_connect_as__refresh_link')}
                                </Button>
                            </ButtonWrapper>
                        </PageFormRow>
                        {platformTarget === 'desktop' && (
                            <>
                                <PageFormRow>
                                    <EllipsisLink
                                        value={connectionToken ? generateConnectionLink() : ' '}
                                        icon={copyChip}
                                    />
                                </PageFormRow>
                                {platformTarget == 'desktop' && (
                                    <IncognitoToggle
                                        checked={openIncognito}
                                        disabled={!hasExtension}
                                        onClick={() => {
                                            setOpenIncognito(!openIncognito);
                                        }}
                                    />
                                )}
                            </>
                        )}
                        {qrcodeData !== '' && (
                            <PageFormRow>
                                <Card>
                                    <QrCodeSide>
                                        <QrCodeTextContainer>
                                            <CardBoldText>
                                                {t('admin:page_connect_as__qrcode_text_emphasized')}
                                            </CardBoldText>
                                            <CardLightText>
                                                {t('admin:page_connect_as__qrcode_text_regular')}
                                            </CardLightText>
                                        </QrCodeTextContainer>
                                        <QrCodeWrapper>
                                            <img src={qrcodeData} height="180" />
                                        </QrCodeWrapper>
                                    </QrCodeSide>
                                    <UrlSide>
                                        <CardBoldText>
                                            {t('admin:page_connect_as__url_text')}
                                        </CardBoldText>
                                        <UrlWrapper>
                                            <EllipsisLink
                                                value={
                                                    connectionToken ? generateConnectionLink() : ' '
                                                }
                                                icon={copyChip}
                                            />
                                        </UrlWrapper>
                                    </UrlSide>
                                </Card>
                            </PageFormRow>
                        )}
                    </>
                )}
            </BorderedContainer>

            {platformTarget === 'desktop' && (
                <SubmitButton
                    text={t('admin:page_connect_as__button_connect_as_name', {
                        name: user?.name,
                    })}
                    onClick={onSubmit}
                    disabled={!isFormValid || platformTarget !== 'desktop'}
                    link={hasExtension && openIncognito ? undefined : buttonLink()}
                    dataTrackId="admin_connect_as__click__desktop"
                />
            )}
        </AdminPage>
    );
};

export default ConnectAs;
