import { SuccessResponse } from 'app/api/types';
import {
    CreateGroupParams,
    CreateSectionParams,
    Group,
    GroupSection,
} from 'app/api/types/groupsV2';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'sections';
const GROUP_RESOURCE_URI = 'groups';

class GroupV2 extends ApiResourceBaseClass {
    static getSection(sectionId: string): Promise<GroupSection> {
        return GroupV2.get(`${RESOURCE_URI}/${sectionId}`).then(({ data }) => data);
    }

    static createSection(sectionCreateData: CreateSectionParams): Promise<SuccessResponse> {
        return GroupV2.post(RESOURCE_URI, sectionCreateData).then(({ data }) => data);
    }

    static updateSection(
        sectionId: string,
        sectionCreateData: CreateSectionParams,
    ): Promise<SuccessResponse> {
        return GroupV2.post(`${RESOURCE_URI}/${sectionId}`, sectionCreateData).then(
            ({ data }) => data,
        );
    }

    static deleteSection(sectionId: string): Promise<SuccessResponse> {
        return GroupV2.delete(`${RESOURCE_URI}/${sectionId}`).then(({ data }) => data);
    }

    static createGroup(
        sectionId: string,
        sectionGroupData: CreateGroupParams,
    ): Promise<SuccessResponse> {
        return GroupV2.post(
            `${RESOURCE_URI}/${sectionId}/${GROUP_RESOURCE_URI}`,
            sectionGroupData,
        ).then(({ data }) => data);
    }

    static getGroup(sectionId: string, groupId: string): Promise<Group> {
        return GroupV2.get(`${RESOURCE_URI}/${sectionId}/${GROUP_RESOURCE_URI}/${groupId}`).then(
            ({ data }) => data,
        );
    }

    static deleteGroup(sectionId: string, groupId: string): Promise<SuccessResponse> {
        return GroupV2.delete(`${RESOURCE_URI}/${sectionId}/${GROUP_RESOURCE_URI}/${groupId}`).then(
            ({ data }) => data,
        );
    }

    static updateGroup(
        sectionId: string,
        groupId: string,
        sectionGroupData: CreateGroupParams,
    ): Promise<SuccessResponse> {
        return GroupV2.post(
            `${RESOURCE_URI}/${sectionId}/${GROUP_RESOURCE_URI}/${groupId}`,
            sectionGroupData,
        ).then(({ data }) => data);
    }
}

export default GroupV2;
