import { useMemo } from 'react';

import { GetSectionsParams, GetSectionsReceivedData } from '@partoohub/api-client';
import { UseInfiniteQueryOptions, UseInfiniteQueryResult, useInfiniteQuery } from 'react-query';

import { GroupSectionList } from 'app/api/types/groupsV2';
import { groupApiClient } from 'app/api/v2/api_calls/apiResources';
import { GROUPS_V2 } from 'app/common/data/queryKeysConstants';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const hasAnyGroup = (sections: GroupSectionList | undefined): boolean => {
    return !!sections?.some(section => section.groups.length);
};

export const hasAnyGroupWithBusinessCount = (sections: GroupSectionList | undefined): boolean => {
    return !!sections?.some(section => section.groups.some(group => group.business_count > 0));
};

export const useSectionMapping = (
    sections: GroupSectionList | undefined,
): Record<string, string> => {
    return useMemo(() => {
        const mapping: Record<string, string> = {};
        (sections ?? []).forEach(section => {
            section.groups.forEach(group => {
                mapping[group.id.toString()] = section.id.toString();
            });
        });
        return mapping;
    }, [sections]);
};

export default function useGroupsV2(
    searchFilters: GetSectionsParams = {},
    options: UseInfiniteQueryOptions<GetSectionsReceivedData> = {},
): {
    query: UseInfiniteQueryResult<GetSectionsReceivedData>;
    sections: GroupSectionList | undefined;
} {
    const { data: me } = useMe();
    if (!searchFilters.org_id && me?.org_id) {
        searchFilters.org_id = me.org_id;
    }

    const query = useInfiniteQuery(
        [GROUPS_V2, searchFilters],
        ({ pageParam: page = 1 }) =>
            groupApiClient.getSections({
                ...searchFilters,
                page,
            }),
        {
            getNextPageParam: lastPage =>
                lastPage.page < lastPage.max_page ? lastPage.page + 1 : undefined,
            ...options,
        },
    );

    const sections = query.data?.pages?.map(({ sections }) => sections).flat();

    return { query, sections };
}
