import { FC, useState } from 'react';

import { Option, SingleSelect, TextInput, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';

import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import { GOOGLE_ACCOUNTS } from 'admin/common/data/queryKeysConstants';

import { convertGmbAccountToOption } from './convertGmbAccountToOption';

const GoogleAccountRefresh: FC = () => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [googleAccount, setGoogleAccount] = useState<Option | undefined>(undefined);
    const [name, setName] = useState<string | undefined>('');
    const [storeCode, setStoreCode] = useState<string | undefined>('');
    const [locality, setLocality] = useState<string | undefined>('');
    const [postalCode, setPostalCode] = useState<string | undefined>('');
    let googleAccountOptions: Option[] = [];

    const { data, isLoading } = useQuery([GOOGLE_ACCOUNTS, { searchValue }], () =>
        api.account.searchAllGoogleAccounts(searchValue, true),
    );
    if (!isLoading) {
        googleAccountOptions = data?.gmb_accounts.map(convertGmbAccountToOption) || [];
    }

    const refreshGoogleAccount = async () => {
        const res = await api.account.refreshGoogleAccount(
            googleAccount?.value ?? '',
            name || '',
            storeCode || '',
            locality || '',
            postalCode || '',
        );
        if (res.status == 'started') {
            toast.loading(
                t('admin:page_google_account_refresh__toast_success__description'),
                t('admin:page_google_account_refresh__toast_success__title'),
            );
        } else {
            toast.error(
                t('admin:page_google_account_refresh__toast_error__description'),
                t('admin:page_google_account_refresh__toast_error__title'),
            );
        }
    };

    return (
        <AdminPage
            dataTrackId="page_google_account_refresh"
            title={t('admin:page_google_account_refresh__title')}
            description={t('admin:page_google_account_refresh__description')}
            infoLink="https://partoo.elium.com/tile/view/1988"
        >
            <BorderedContainer>
                <PageFormRow>
                    <SingleSelect
                        dataTrackId="page_google_account_refresh__select_account"
                        label={t('admin:page_google_account_refresh__account_select_field')}
                        placeholder=""
                        sections={[{ options: googleAccountOptions }]}
                        onChange={setGoogleAccount}
                        onSearch={setSearchValue}
                        selectedValue={googleAccount}
                        maxHeight={300}
                        required
                    />
                </PageFormRow>
                <PageFormRow>
                    <TextInput
                        dataTrackId="google_account_refresh__name"
                        value={name}
                        onChange={setName}
                        placeholder={t('admin:page_google_account_refresh__name_field__title')}
                        notice={t('admin:page_google_account_refresh__name_field__description')}
                    />
                    <TextInput
                        dataTrackId="google_account_refresh__store_code"
                        value={storeCode}
                        onChange={setStoreCode}
                        placeholder={t(
                            'admin:page_google_account_refresh__store_code_field__title',
                        )}
                    />
                </PageFormRow>
                <PageFormRow>
                    <TextInput
                        dataTrackId="google_account_refresh__locality"
                        value={locality}
                        onChange={setLocality}
                        placeholder={t('admin:page_google_account_refresh__locality_field__title')}
                    />
                    <TextInput
                        dataTrackId="google_account_refresh__postal_code"
                        value={postalCode}
                        onChange={setPostalCode}
                        placeholder={t(
                            'admin:page_google_account_refresh__postal_code_field__title',
                        )}
                    />
                </PageFormRow>
            </BorderedContainer>
            <SubmitButton
                dataTrackId="admin_google_account_refresh__refresh_account"
                onClick={refreshGoogleAccount}
                text={t('admin:page_google_account_refresh__submit_button')}
                disabled={!googleAccount}
            />
        </AdminPage>
    );
};

export default GoogleAccountRefresh;
