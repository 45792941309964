import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import NotFoundTemplate from 'admin/common/components/templates/NotFoundTemplate/NotFoundTemplate';

import { CategoryListingEmptyContainer } from './CategoryListingEmpty.styled';

export const CategoryListingEmpty = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const clickHandlerEmptyCategories = () => {
        navigate('/categories');
    };

    const illustrationElement: IllustrationLaptopElement = {
        name: IllustrationLaptopEnum.Happy,
        type: IllustrationCategoryEnum.Laptop,
    };

    return (
        <CategoryListingEmptyContainer>
            <NotFoundTemplate
                show
                title={t('admin_categories_listing_empty_message1')}
                subtitle={t('admin_categories_listing_empty_message2')}
                imgSrc={illustrationElement}
                buttonText={t('admin_categories_listing_empty_button')}
                handleClick={clickHandlerEmptyCategories}
                withButton
            />
        </CategoryListingEmptyContainer>
    );
};
