import { ApiResourceBaseClass } from '@partoohub/api-client';

import { RegionData } from 'admin/api/types';

export const ADMINISTRATIVE_REGIONS_RESOURCE_URI = 'supported_administrative_regions';

export class AdministrativeRegions extends ApiResourceBaseClass {
    async getRegions(countryCode: string): Promise<RegionData> {
        const params = {
            code: countryCode,
        };
        const { data } = await this.get<RegionData>(
            `${ADMINISTRATIVE_REGIONS_RESOURCE_URI}`,
            params,
        );
        return data;
    }

    async getCountries(): Promise<RegionData> {
        const { data } = await this.get<RegionData>(ADMINISTRATIVE_REGIONS_RESOURCE_URI);
        return data;
    }
}
