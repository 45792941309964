import React, { useEffect, useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { DatePickerType, Filters, OverrideDatePicker, RangeDate } from '@partoohub/ui';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';

import { DailyReportLog, LogsAdminApiCall } from 'app/api/v2/api_calls/logsApiCalls';

import AdminPage from 'admin/common/components/templates/AdminPage';
import NotFoundTemplate from 'admin/common/components/templates/NotFoundTemplate/NotFoundTemplate';
import { DEFAULT_LOCALE_DATE_PICKER } from 'admin/common/data/locale';
import { DAILY_REPORTS } from 'admin/common/data/queryKeysConstants';
import { DATE_FORMAT } from 'admin/common/utils/dates';
import { getSelectedOptions } from 'admin/common/utils/selectOptions';
import {
    DateFilterWrapper,
    FiltersWrapper,
} from 'admin/content/logs/common/components/Filters.styled';
import { EmptyPlaceholder, HeaderRow } from 'admin/content/logs/common/components/List.styled';
import {
    DailyReportIconWrapper,
    DailyReportTypeFilterWrapper,
    LeftCell,
    MiddleCell,
    RightCell,
} from 'admin/content/logs/pages/DailyReport/components/DailyReport.styled';

import { DailyReportTable } from 'admin/content/logs/pages/DailyReport/components/DailyReportTable';

import { dailyReportCategoryMapping } from './components/DailyReportRowTop';

const DailyReport: React.FC = () => {
    const { t } = useTranslation();

    const dailyReportCategoryOptions = Object.entries(dailyReportCategoryMapping)
        .filter(([_, dailyReportInfo]) => !dailyReportInfo?.disabled)
        .map(([dailyReportCategoryId, dailyReportInfo]) => ({
            value: dailyReportCategoryId,
            label: t(dailyReportInfo.translationKey),
            icon: (
                <DailyReportIconWrapper key={dailyReportCategoryId}>
                    <i className={dailyReportInfo.fontAwesomeIcon} />
                </DailyReportIconWrapper>
            ),
            name: dailyReportCategoryId,
        }));

    const [logs, setLogs] = useState<DailyReportLog[]>([]);
    const [dates, setDates] = useState<RangeDate>([null, null]);

    const defaultTypes = Object.fromEntries(
        dailyReportCategoryOptions.map(({ value }) => [value, false]),
    );

    const [typesFilter, setTypesFilter] = useState(defaultTypes);
    const listOfSelectedTypes = getSelectedOptions(typesFilter);
    const resetFilters = () => {
        setDates([null, null]);
        setTypesFilter(defaultTypes);
    };

    const hasFiltersSet = !!dates[0] || !!listOfSelectedTypes.length;

    const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery(
        [DAILY_REPORTS, JSON.stringify(listOfSelectedTypes), dates[0]],
        ({ pageParam = 1 }) =>
            LogsAdminApiCall.dailyReports({
                categories: listOfSelectedTypes?.length ? listOfSelectedTypes.join(',') : undefined,
                created_at: dates[0] ? format(dates[0], DATE_FORMAT) : undefined,
                page: pageParam,
            }),
        {
            getNextPageParam: lastPage =>
                lastPage.max_page > lastPage.page ? lastPage.page + 1 : undefined,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            staleTime: Infinity,
        },
    );
    useEffect(() => {
        if (!isFetching) {
            setLogs(data?.pages?.map(({ logs }) => logs)?.flat() || []);
        }
    }, [data]);

    const illustrationElement: IllustrationLaptopElement = {
        name: IllustrationLaptopEnum.Happy,
        type: IllustrationCategoryEnum.Laptop,
    };

    return (
        <AdminPage
            dataTrackId="page_daily_report"
            title={t('admin:page_daily_report__title')}
            description={t('admin:page_daily_report__description')}
            infoLink="https://partoo.elium.com/tile/view/1834"
        >
            <FiltersWrapper>
                <DateFilterWrapper>
                    <OverrideDatePicker
                        dataTrackId="page_daily_report__select_date"
                        type={DatePickerType.DateSelector}
                        labels={{ startDate: t('date') }}
                        dates={dates}
                        onChange={setDates}
                        placeholders={{ startDate: t('date_placeholder') }}
                        localeDatePicker={DEFAULT_LOCALE_DATE_PICKER}
                    />
                </DateFilterWrapper>
                <DailyReportTypeFilterWrapper>
                    <Filters
                        menuListArray={[{ options: dailyReportCategoryOptions }]}
                        selectedItems={typesFilter}
                        onChange={setTypesFilter}
                        multiple
                        placeholder={t('admin:page_daily_report__puller_type_filter')}
                        dataTrackId="page_daily_report__select_puller"
                        maxHeight={500}
                        position="bottom"
                    />
                </DailyReportTypeFilterWrapper>
            </FiltersWrapper>
            <HeaderRow>
                <LeftCell>{t('admin:page_daily_report__type')}</LeftCell>
                <MiddleCell>{t('admin:page_daily_report__date')}</MiddleCell>
                <RightCell></RightCell>
            </HeaderRow>
            {!logs?.length && !isFetching ? (
                <EmptyPlaceholder>
                    <NotFoundTemplate
                        show
                        title={t('no-result-found')}
                        imgSrc={illustrationElement}
                        subtitle={
                            hasFiltersSet ? t('page_daily_report__empty_placeholder__subtitle') : ''
                        }
                        buttonText={t('page_daily_report__empty_placeholder__button')}
                        handleClick={resetFilters}
                        withButton={hasFiltersSet}
                    />
                </EmptyPlaceholder>
            ) : (
                <DailyReportTable
                    logs={logs}
                    isFetching={isFetching}
                    fetchNextLogs={fetchNextPage}
                    allLogsFetched={!hasNextPage}
                />
            )}
        </AdminPage>
    );
};

export default DailyReport;
