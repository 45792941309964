import React, { PureComponent } from 'react';

import { cloneDeep, debounce } from 'lodash-es';

import { InputEvent } from 'app/api/types';
import { Choice } from 'app/api/types/user';

import Clickable from 'app/common/components/buttons/Clickable';
import Menu from 'app/common/designSystem/components/atoms/CommonSelectMenu/CommonSelectMenu';
import explanationHOC from 'app/common/designSystem/components/atoms/ExplanationHOC';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

export type Props = {
    placeholder: string;
    options: Array<Choice>;
    // click on item
    onChange: (arg0: Choice | null) => void;

    // Optional props
    selectedValue?: Choice | null;
    explanation?: string;
    disabled?: boolean;
    // text of the tooltip
    textTooltip?: string;
    // display the loading and call loadMore if scrolled to bottom
    hasMore?: boolean;
    hasError?: boolean;
    hasWarning?: boolean;
    hasEmptyValue?: boolean;
    // called when scrolled with hasMore, or when currentSearch changed
    loadMore?: (arg0: string) => void;
    // display the loading, do not use with hasMore
    isLoading?: boolean;
    isMandatory?: boolean;
    // default true. disable automatic filter on options array.
    mustFilterOptions?: boolean;
    // only filter if word startsWith
    searchBeginningWords?: boolean;
    // hide cross to remove selected value
    hideCross?: boolean;
    // Hide explanation text
    hideExplanation?: boolean;
    // display the selected option on one single line
    displaySelectedOptionOneLine?: boolean;
    // calls loadMore when the menu is opened if true
    loadMoreOnOpenMenu?: boolean;
    // on hover item
    onChangePreselectedValue?: (value?: string | null) => void;
    flexEnd?: boolean;
    input?: JSX.Element;
    dataTestId?: string;
    // display the option in full length in a title
    titleOnOptions?: boolean;
    dataTrack?: string; // Used as static HTML identifier
};

type State = {
    wrapperRef: HTMLElement | null;
    isFocused: boolean;
    currentSearch: string;
    inputRef: HTMLInputElement | null;
    hiddenSpanRef: HTMLElement | null;
};

/**
 * @deprecated
 */
class AsyncSingleSelect extends PureComponent<Props, State> {
    static defaultProps = {
        explanation: undefined,
        hideExplanation: false,
        disabled: false,
        textTooltip: undefined,
        hasMore: false,
        hasError: false,
        hasWarning: false,
        hasEmptyValue: false,
        loadMore: () => undefined,
        isLoading: false,
        isMandatory: false,
        mustFilterOptions: true,
        hideCross: false,
        displaySelectedOptionOneLine: false,
        loadMoreOnOpenMenu: false,
        onChangePreselectedValue: () => undefined,
        flexEnd: false,
        input: undefined,
        searchBeginningWords: false,
        titleOnOptions: false,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            wrapperRef: null,
            isFocused: false,
            currentSearch: '',
            inputRef: null,
            hiddenSpanRef: null,
        };
    }

    openMenu = () => {
        const { isFocused, inputRef } = this.state;
        const { loadMoreOnOpenMenu, loadMore } = this.props;

        if (!isFocused) {
            document.addEventListener('mousedown', this.handleClickOutside);
            this.setState({
                isFocused: true,
                currentSearch: '',
            });
        }

        if (loadMoreOnOpenMenu && loadMore) {
            loadMore('');
        }

        if (inputRef) inputRef.focus();
    };

    closeMenu = () => {
        const { isFocused } = this.state;
        const { loadMore } = this.props;

        if (isFocused) {
            document.removeEventListener('mousedown', this.handleClickOutside);
            this.setState({
                isFocused: false,
                currentSearch: '',
            });
        }

        if (loadMore) loadMore('');
    };

    updateCurrentValue = (value: Choice, e: Event) => {
        e.preventDefault();
        e.stopPropagation();
        const { onChange, hasEmptyValue, selectedValue } = this.props;

        if (
            (selectedValue && selectedValue.label === value.label) ||
            (value.label === '' && value.value === '' && hasEmptyValue)
        ) {
            onChange(null);
        } else {
            onChange(value);
        }

        this.closeMenu();
    };

    reset = () => {
        const { onChange } = this.props;
        onChange(null);
    };

    handleClickOutside = (event: Event) => {
        const { isFocused, wrapperRef } = this.state;

        if (
            isFocused &&
            wrapperRef &&
            event.target instanceof Node &&
            !wrapperRef.contains(event.target)
        ) {
            this.closeMenu();
        }
    };

    onInput = (e: InputEvent) => {
        const { inputRef, hiddenSpanRef } = this.state;

        if (hiddenSpanRef && inputRef) {
            hiddenSpanRef.textContent = e.target.value;
            inputRef.style.width = '100%';
        }

        this.setState(
            {
                currentSearch: e.target.value,
                isFocused: true,
            },
            () => {
                this.debouncedLoadMore();
            },
        );
    };

    debouncedLoadMore = debounce(() => {
        const { loadMore } = this.props;
        const { currentSearch } = this.state;
        if (loadMore) loadMore(currentSearch);
    }, 250);

    setWrapperRef = (ref: HTMLElement | null) => {
        this.setState({
            wrapperRef: ref,
        });
    };

    setInputRef = (ref: HTMLInputElement | null) => {
        this.setState({
            inputRef: ref,
        });
    };

    setHiddenSpanRef = (ref: HTMLElement | null) => {
        this.setState({
            hiddenSpanRef: ref,
        });
    };

    render() {
        const {
            placeholder,
            options,
            disabled,
            textTooltip,
            hasError,
            hasWarning,
            hasEmptyValue,
            isLoading,
            isMandatory,
            hasMore,
            loadMore,
            mustFilterOptions,
            searchBeginningWords,
            selectedValue,
            hideCross,
            displaySelectedOptionOneLine,
            onChangePreselectedValue,
            flexEnd,
            input,
            dataTestId,
            titleOnOptions,
            dataTrack,
        } = this.props;
        const { isFocused, currentSearch } = this.state;
        let modifier = '';

        if (isFocused && hasError) {
            modifier = '--focused_error';
        } else if (hasError) {
            modifier = '--error';
        } else if (isFocused && hasWarning) {
            modifier = '--focused_warning';
        } else if (hasWarning) {
            modifier = '--warning';
        } else if (isFocused) {
            modifier = '--focused';
        } else {
            modifier = '--unfocused';
        }

        const optionsCopy = cloneDeep(options);

        if (hasEmptyValue) {
            optionsCopy.unshift({
                label: '',
                value: '',
            });
        }
        let menuOptions;
        if (mustFilterOptions) {
            if (searchBeginningWords) {
                menuOptions = optionsCopy.filter(
                    ({ label }) =>
                        (label || '')
                            .toLowerCase()
                            .split(/[\s-]+/)
                            .filter(word => word.startsWith(currentSearch.toLowerCase())).length,
                );
            } else {
                menuOptions = optionsCopy.filter(({ label }) =>
                    (label || '').toLowerCase().includes(currentSearch.toLowerCase()),
                );
            }
        } else {
            menuOptions = optionsCopy;
        }

        const clonedElementWithMoreProps =
            input &&
            React.cloneElement(input, {
                onClick: this.openMenu,
            });
        const component = (
            <div
                className={`
                    multiple_select
                    ${flexEnd ? 'flex--justify_end' : ''}
                    ${disabled ? 'multiple_select--disabled' : ''}
                `}
                ref={this.setWrapperRef}
                {...(dataTestId ? { 'data-testid': dataTestId } : {})}
                data-track={dataTrack}
                data-intercom-target={dataTrack}
            >
                {!isFocused && input ? (
                    clonedElementWithMoreProps
                ) : (
                    <Clickable
                        className={`
                            multiple_select__field
                            multiple_select__field--async
                            multiple_select__field${modifier}
                            ${disabled ? 'multiple_select__field--disabled' : ''}
                        `}
                        onClick={this.openMenu}
                        disabled={disabled}
                    >
                        <div
                            className={`
                                multiple_select__placeholder
                                ${isFocused ? 'multiple_select__placeholder--focused' : ''}
                                ${hasError ? 'multiple_select__placeholder--error' : ''}
                                ${
                                    hasWarning && !hasError
                                        ? 'multiple_select__placeholder--warning'
                                        : ''
                                }
                                ${
                                    selectedValue || isFocused
                                        ? 'multiple_select__placeholder--top'
                                        : ''
                                }
                            `}
                        >
                            <div>
                                {placeholder}
                                {isMandatory && <span className="mandatory-field"> *</span>}
                            </div>
                        </div>
                        <div
                            className={`
                                multiple_select__field_items
                                multiple_select__field_items--relative
                                ${
                                    displaySelectedOptionOneLine
                                        ? 'multiple_select__field_items--one_line'
                                        : ''
                                }
                                ${isFocused ? 'multiple_select__field_items--focused' : ''}
                            `}
                        >
                            {!currentSearch && selectedValue && (
                                <div
                                    className={`
                                        single-select__selected_item
                                        ${isFocused ? 'single-select__selected_item--focused' : ''}
                                    `}
                                    title={titleOnOptions ? selectedValue.label : ''}
                                >
                                    {selectedValue?.icon && (
                                        <i
                                            className={`${selectedValue.icon}  margin_right--simple`}
                                        />
                                    )}
                                    {selectedValue.label}
                                </div>
                            )}
                            {isFocused && (
                                <div className="async-input async-input--single">
                                    <span ref={this.setHiddenSpanRef} />
                                    <input
                                        ref={this.setInputRef}
                                        type="text"
                                        // @ts-ignore
                                        onInput={e => this.onInput(e)}
                                        autoFocus
                                    />
                                </div>
                            )}
                        </div>
                        {selectedValue && !hideCross && (
                            <button
                                type="button"
                                className="multiple_select__field__icon_button"
                                onClick={this.reset}
                            >
                                <i
                                    className={`fas fa-times ${
                                        hasError ? 'multiple_select__field__icon_button--error' : ''
                                    }`}
                                />
                            </button>
                        )}
                        {(!selectedValue || hideCross) && (
                            <button type="button" className="multiple_select__field__icon_button">
                                <i
                                    className={`fas fa-caret-down ${
                                        hasError ? 'multiple_select__field__icon_button--error' : ''
                                    }`}
                                />
                            </button>
                        )}
                    </Clickable>
                )}
                {isFocused && (
                    <Menu
                        options={menuOptions}
                        selectedOptions={selectedValue ? [selectedValue] : []}
                        onClick={this.updateCurrentValue}
                        closeMenu={this.closeMenu}
                        hasMore={hasMore}
                        loadMore={() => {
                            if (loadMore) loadMore(currentSearch);
                        }}
                        isLoading={isLoading}
                        onChangePreselectedValue={onChangePreselectedValue}
                        titleOnOptions={titleOnOptions}
                    />
                )}
            </div>
        );
        return (
            <div className="flex--col async-select__size">
                {textTooltip ? (
                    <TooltipWrapper text={textTooltip} position="bottom-start">
                        {component}
                    </TooltipWrapper>
                ) : (
                    component
                )}
            </div>
        );
    }
}

export default explanationHOC<Props>(AsyncSingleSelect);
