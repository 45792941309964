import React, { FC, Fragment, useMemo, useState } from 'react';

import { Button, Drawer, List } from '@partoohub/ui';

import { uniqBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useInfiniteQuery } from 'react-query';

import { useSearchParams } from 'react-router';

import { GoogleAdminListingData } from 'app/api/types/googleAdminManagement';
import api from 'app/api/v2/api_calls';

import AdminPage from 'admin/common/components/templates/AdminPage';

import { PageDescription } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import { GOOGLE_ADMIN_MANAGEMENT } from 'admin/common/data/queryKeysConstants';
import GoogleAdminListingRow from 'admin/content/playground/pages/GoogleAdminManagement/GoogleAdminListingRow/GoogleAdminListingRow';
import RequestAdminContent from 'admin/content/playground/pages/GoogleAdminManagement/RequestAdminContent/RequestAdminContent';

const GoogleAdminManagement: FC = () => {
    const { t } = useTranslation();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentSearch, setCurrentSearch] = useState(searchParams.get('search_query') || '');
    const [displaySearchBar, setDisplaySearchBar] = useState(!!currentSearch);

    const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery(
        [GOOGLE_ADMIN_MANAGEMENT, currentSearch],
        ({ pageParam = 1 }) =>
            api.googleAdminManagement.fetchGoogleAdminRequests(pageParam, {
                search_query: currentSearch,
            }),
        {
            getNextPageParam: lastPage =>
                lastPage.max_page > lastPage.page ? lastPage.page + 1 : undefined,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    );

    const googleAdminListings = useMemo(
        () => uniqBy(data?.pages?.flatMap(page => page.listings) ?? [], invoice => invoice.id),
        [data],
    );

    const toggleSearchBar = () => {
        if (!displaySearchBar || (displaySearchBar && (!currentSearch || !currentSearch.length))) {
            setDisplaySearchBar(!displaySearchBar);
        }
    };

    const updateCurrentSearch = (value?: string) => {
        setCurrentSearch(value || '');
        const newParams = {
            ...searchParams,
            search_query: value,
        };
        if (!value) {
            delete newParams.search_query;
        }
        setSearchParams(newParams);
    };

    const columns: any[] = [
        { label: t('created_on') },
        { label: 'Location Name' },
        { label: 'Location ID' },
        { label: t('admin:status') },
        { label: 'Origin' },
        { label: '', width: '40px' },
    ];

    function formatDescription(text: string) {
        return text.split('\n').map((line: string, index) => (
            <Fragment key={`${line}-${index}`}>
                {line.replace(/\\n /g, '')}
                <br />
            </Fragment>
        ));
    }

    return (
        <AdminPage
            dataTrackId="slap_admin_page_title"
            title={t('admin:slap__admin_page_title')}
            displaySearchBar={displaySearchBar}
            toggleSearchBar={toggleSearchBar}
            currentSearch={currentSearch}
            updateCurrentSearch={updateCurrentSearch}
            searchPlaceholder={t('admin:slap__search_placeholder')}
            buttons={
                <Button onClick={() => setOpenDrawer(true)} dataTrackId="slap_open_drawer_request">
                    {t('admin:slap__button_request_text')}
                </Button>
            }
        >
            <PageDescription>
                {formatDescription(t('admin:slap__admin_page_description'))}
            </PageDescription>
            <Drawer
                dataTrackId="slap_request_drawer"
                isOpen={openDrawer}
                onHide={() => setOpenDrawer(false)}
            >
                <RequestAdminContent />
            </Drawer>
            <InfiniteScroll
                hasMore={hasNextPage && !isFetchingNextPage}
                loadMore={() => fetchNextPage()}
                useWindow={false}
                getScrollParent={() => document.getElementById('react-content')}
            >
                <List columns={columns} loading={isLoading}>
                    {googleAdminListings.length > 0 &&
                        googleAdminListings.map((googleAdminListing: GoogleAdminListingData) => (
                            <GoogleAdminListingRow
                                key={googleAdminListing.id}
                                googleAdminListing={googleAdminListing}
                            />
                        ))}
                </List>
            </InfiniteScroll>
        </AdminPage>
    );
};

export default GoogleAdminManagement;
