import { ReactNode } from 'react';

import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';
import { Pagination, Text } from '@partoohub/ui';

import { ChevronDown } from 'admin/common/components/icons/ChevronDown';

export const Row = styled.div`
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.shadows.initial};
    &:hover {
        box-shadow: ${({ theme }) => theme.shadows.hover};
    }
`;

export const RowWithLayout = styled(Row)`
    display: 'flex';
    cursor: pointer;
`;

export const QuickConnectAction = styled.summary`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 5px;
`;
export const DisclosureTrigger = styled.summary`
    list-style: none;
    &::-webkit-details-marker {
        display: none;
    }

    ${({ expanded, theme }: { expanded: boolean; theme: Theme }) =>
        expanded &&
        'border-bottom-style: solid;' +
            'border-bottom-width: 1px;' +
            `border-bottom-color: ${theme.colors.default.alpha};`}

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
    gap: 16px;
`;

export const StyledChevron = styled(ChevronDown)`
    color: ${HEX_COLORS.secondary};
    ${({ upward }: { upward: boolean }) => upward && 'transform: rotate(0.5turn);'}
`;

export const BusinessName = styled(
    ({ children, className }: { children?: ReactNode; className?: string }) => (
        <Text className={className} variant="bodyMBold">
            {children}
        </Text>
    ),
)`
    display: flex;
`;

export const BulkButtonWrapper = styled.div`
    display: flex;
    gap: 5px;
`;

export const PaginationLayout = styled(Pagination)`
    justify-content: center;
`;
