import { parseQuery } from '@partoohub/utils';
import { useLocation } from 'react-router';

/**
 * Get a search param from the current location
 */
export const useSearchParam = (name: string): string => getSearchParam(useLocation().search, name);

/**
 * Imperative version for usage in non-hooks environments (global scope)
 */
export const getSearchParam = (search: string, paramName: string): string => {
    const { [paramName]: value } = parseQuery(search);

    const newValue = Array.isArray(value) ? value.join(',') : value;

    return newValue || '';
};
