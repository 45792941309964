import styled from '@emotion/styled';
import { HEX_COLORS, convertToRgba } from '@partoohub/branding';

export const BusinessLoadingCardContainer = styled.div`
    height: 66px;
    width: 100%;
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.shadows.initial};
    padding: 8px;
`;

export const BusinessLoadingPhoto = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 8px;
`;

export const BusinessCardGridItemContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const BusinessFullAddress = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const BusinessImage = styled.div`
    display: flex;
    border-radius: 8px;
    height: 50px;
    width: 50px;
    background-color: ${convertToRgba(HEX_COLORS.blackberry, 0.12)};
    margin-right: 8px;
`;

export const BusinessTitle = styled.div`
    height: 14px;
    background-color: ${convertToRgba(HEX_COLORS.blackberry, 0.12)};
    margin-bottom: 10px;
`;

export const BusinessAddress = styled.div`
    height: 14px;
    background-color: ${convertToRgba(HEX_COLORS.blackberry, 0.12)};
`;
