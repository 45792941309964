import { Option, SingleSelect } from '@partoohub/ui';

import { ERROR, FIELD_ERROR, PENDING, SUCCESS } from 'admin/common/data/constants/publisherStates';

type Props = {
    state: Option | undefined;
    setState: (selectedItems: Option | undefined) => void;
};

const options = [
    { value: SUCCESS, label: 'Success', name: SUCCESS },
    { value: PENDING, label: 'Pending', name: PENDING },
    { value: FIELD_ERROR, label: 'Field Error', name: FIELD_ERROR },
    { value: ERROR, label: 'External Error', name: ERROR },
];
const sections = [{ options: options }];

export const StateSelect = ({ state, setState }: Props) => (
    <SingleSelect
        dataTrackId="overriding-state-type"
        sections={sections}
        selectedValue={state}
        onChange={setState}
        label="State (Without selected state, remove current overriding state)"
        maxHeight={300}
    />
);
