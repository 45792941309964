import {
    CreateFeedbackFormPayload,
    FeedbackFormData,
    ReceivedFeedbackFormData,
    UpdateFeedbackFormPayload,
} from 'app/api/types/feedbackForm';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'feedback/feedback_form';

export class FeedbackForm extends ApiResourceBaseClass {
    static async getFeedbackForms(orgId: number): Promise<ReceivedFeedbackFormData> {
        const { data } = await FeedbackForm.get(RESOURCE_URI, {
            org_id: orgId,
        });
        return data;
    }

    static async updateFeedbackForm(
        formId: string,
        payload: UpdateFeedbackFormPayload,
    ): Promise<FeedbackFormData> {
        const { data } = await FeedbackForm.put(`${RESOURCE_URI}/${formId}`, payload);

        return data;
    }

    static async createFeedbackForm(payload: CreateFeedbackFormPayload): Promise<FeedbackFormData> {
        const { data } = await FeedbackForm.post(RESOURCE_URI, payload);

        return data;
    }
}
