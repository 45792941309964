import { FC, useCallback, useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Button, Modal, Option, SingleSelect, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { DiffusionCleanerAdminApiCall } from 'admin/common/api/calls/diffusionCleanerApiCalls';
import { DiffusionCleanerExportFilters } from 'admin/common/api/types/diffusion_cleaner';
import { languagesData } from 'admin/common/data/languages';

import { getSelectedOptions } from 'admin/common/utils/selectOptions';

import { DialogStyled } from './DiffusionCleanerLangSelect.styled';

type Props = {
    openedWarningModal: boolean;
    setOpenedWarningModal: (openedWarningModal: boolean) => void;
    filters: DiffusionCleanerExportFilters;
};

export const DiffusionCleanerLangSelect: FC<Props> = ({
    openedWarningModal,
    setOpenedWarningModal,
    filters,
}: Props) => {
    const { t } = useTranslation();
    const langOptions: Option[] = languagesData
        .filter(({ supportedForDemo }) => supportedForDemo)
        .map(({ code }) => ({
            label: t(`original-${code}`),
            name: code,
            value: code,
        }));
    const sections = [{ options: langOptions }];

    const [lang, setLang] = useState<Option | undefined>(undefined);
    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name: IllustrationOldEnum.Download,
    };

    const resetModal = () => {
        setOpenedWarningModal(false);
        setLang(undefined);
    };
    const exportDiffusionErrorsMutation = useCallback(
        async (filters: DiffusionCleanerExportFilters, lang: string | undefined) => {
            try {
                const queryParams = {
                    provider: filters.provider?.value,
                    orgId: filters.org?.value,
                    publisher: getSelectedOptions(filters.publishers),
                    errorKeys: getSelectedOptions(filters.errorTypes),
                    lang: lang ? lang : 'en',
                    groups: getSelectedOptions(filters.groups),
                };
                await DiffusionCleanerAdminApiCall.diffusionCleanerExport(queryParams);
                toast.success(
                    t('admin:page_exporter__success_toast__content'),
                    t('admin:page_exporter__success_toast__title'),
                );
            } catch (error: any) {
                toast.error(error.message, t('admin:toast_error__title'));
            } finally {
                resetModal();
            }
        },
        [],
    );

    return (
        <Modal isOpen={openedWarningModal} closeModal={() => resetModal()}>
            <div style={{ width: '500px' }}>
                <DialogStyled
                    dataTrackId=""
                    headerContent={illustrationElement}
                    title={t('admin:page_diffusion_cleaner__download_title')}
                    subTitle={t('admin:page_diffusion_cleaner__download_subtitle')}
                    actions={[
                        <Button
                            dataTrackId="admin_diffusion_cleaner_cancel_download"
                            variant="secondary"
                            size="large"
                            onClick={() => setOpenedWarningModal(false)}
                            appearance="outlined"
                            full
                        >
                            {t('cancel')}
                        </Button>,
                        <Button
                            dataTrackId="admin_diffusion_cleaner_menu_download"
                            variant="primary"
                            size="large"
                            onClick={() => exportDiffusionErrorsMutation(filters, lang?.value)}
                            full
                            disabled={lang == undefined}
                        >
                            {t('download')}
                        </Button>,
                    ]}
                >
                    <SingleSelect
                        dataTrackId="admin_diffusion_cleaner__select_language"
                        sections={sections}
                        selectedValue={lang}
                        onChange={setLang}
                        label={t('admin:page_demo_creator__select_language')}
                        maxHeight={300}
                        required
                    />
                </DialogStyled>
            </div>
        </Modal>
    );
};
