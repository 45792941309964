import { AuthToken, Business, Organization, Provider, Review, User } from '@partoohub/api-client';

import { AdministrativeRegions } from './resources';

// CLIENT API ENDPOINTS
const BASE_URL = `https://${window.location.host}/api/v2`;

export const authTokenApiClient = new AuthToken(BASE_URL);
export const businessApiClient = new Business(BASE_URL);
export const organizationApiClient = new Organization(BASE_URL);
export const providerApiClient = new Provider(BASE_URL);
export const reviewApiClient = new Review(BASE_URL);
export const userApiClient = new User(BASE_URL);

// ADMIN API ENDPOINTS
const ADMIN_BASE_URL = `https://${window.location.host}/admin`;

export const administrativeRegionsApiClient = new AdministrativeRegions(ADMIN_BASE_URL);
