import { FC } from 'react';

import {
    FontAwesomeIconsPartooUsed,
    Icon,
    IconButton,
    IconPrefix,
    List,
    Text,
    Tooltip,
} from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import {
    CategoryStatusType,
    SUCCESS,
    WARNING,
} from 'admin/content/categories/pages/CategoryList/helpers';

import {
    CategoryColumnText,
    CategoryRowWrapper,
    StyledCategoryCardIconToolip,
} from './CategoryListingCard.styled';

type CategoryListingCard = {
    name: string;
    formattedGmbId: string;
    status: CategoryStatusType;
    categoryId: number;
    impactedBusinesses: number;
};

interface CategoryListingCardProps {
    category: CategoryListingCard;
}

export const CategoryListingCard: FC<CategoryListingCardProps> = ({ category }) => {
    const { t } = useTranslation();
    const { name, formattedGmbId, status, categoryId, impactedBusinesses } = category;
    const tmpName = name ?? t('admin_categories_missinglabel');
    const navigate = useNavigate();
    const location = useLocation();

    const onClickEditHandler = () => {
        if ([WARNING, SUCCESS].includes(status)) {
            navigate(`/categories/edit/${categoryId}`, {
                state: {
                    from: location.search,
                },
            });
        } else {
            navigate(`/categories/remap/${categoryId}`, {
                state: {
                    from: location.search,
                },
            });
        }
    };

    const renderStatusIcon = (status: string) => {
        if (status === SUCCESS) {
            return (
                <Icon
                    icon={[FontAwesomeIconsPartooUsed.faCircleCheck, IconPrefix.SOLID]}
                    iconSize="24px"
                    color={status}
                />
            );
        }

        const tooltipText =
            status === WARNING
                ? 'admin_categories_issuetooltip_type2'
                : 'admin_categories_issuetooltip_type1';

        const icon =
            status === WARNING
                ? FontAwesomeIconsPartooUsed.faCircleExclamation
                : FontAwesomeIconsPartooUsed.faCircleXmark;

        return (
            <Tooltip text={t(tooltipText)}>
                <Icon icon={[icon, IconPrefix.SOLID]} iconSize="24px" color={status} />
            </Tooltip>
        );
    };

    const renderName = () => {
        if (tmpName === 'admin_categories_missinglabel') {
            return (
                <>
                    <Text variant="bodyMSemibold" color="secondary" disabled oneLine>
                        {t(tmpName)}
                    </Text>
                    <Tooltip text={t('admin_categories_missinglabel_tooltip')}>
                        <StyledCategoryCardIconToolip
                            icon={[FontAwesomeIconsPartooUsed.faCircleQuestion, IconPrefix.SOLID]}
                            color="secondary"
                        />
                    </Tooltip>
                </>
            );
        }

        return tmpName;
    };

    return (
        <CategoryRowWrapper onClick={onClickEditHandler}>
            <List.Row
                dataTrackId={`page_category_list__row_${categoryId}`}
                id={`${categoryId}`}
                borderColor={status}
            >
                <List.Cell>
                    <CategoryColumnText variant="bodyMSemibold" as="span">
                        {renderName()}
                    </CategoryColumnText>
                </List.Cell>
                <List.Cell>
                    <CategoryColumnText variant="bodyMSemibold" as="span">
                        {formattedGmbId}
                    </CategoryColumnText>
                </List.Cell>
                <List.Cell>
                    <CategoryColumnText variant="bodyMSemibold" as="span">
                        {renderStatusIcon(status)}
                    </CategoryColumnText>
                </List.Cell>
                <List.Cell>
                    <CategoryColumnText variant="bodyMSemibold" as="span">
                        {impactedBusinesses}
                    </CategoryColumnText>
                </List.Cell>
                <List.Cell>
                    <CategoryColumnText variant="bodyMSemibold" as="span">
                        <IconButton
                            dataTrackId="category_card__edit_icon"
                            appearance="outlined"
                            icon={[FontAwesomeIconsPartooUsed.faPenClip, IconPrefix.REGULAR]}
                            onClick={onClickEditHandler}
                        />
                    </CategoryColumnText>
                </List.Cell>
            </List.Row>
        </CategoryRowWrapper>
    );
};
