import styled from '@emotion/styled';

export const DiffusionLoadingDetails = styled.div`
    flex-direction: column;
    align-items: start;
    margin-top: 4px;
    margin-bottom: 8px;
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    border-radius: 4px;
`;
