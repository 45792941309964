import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import { FeedbackReplySuggestionPromptData } from 'app/api/types/feedbackReplySuggestion';
import api from 'app/api/v2/api_calls';

import { FEEDBACK_REPLY_SUGGESTION_PROMPT } from 'admin/common/data/queryKeysConstants';

export const useGetFeedbackReplySuggestionPrompt = (
    orgId: number,
    options: UseQueryOptions<FeedbackReplySuggestionPromptData> = {},
) => {
    const query = useQuery(
        [FEEDBACK_REPLY_SUGGESTION_PROMPT, orgId] as QueryKey,
        () => api.feedbackReplySuggestion.getPrompt(orgId),
        {
            ...options,
            retry: false,
        },
    );

    return [query.data, query] as const;
};
