import styled from '@emotion/styled';

export const ImportLogFilterWrapper = styled.div`
    display: inline-block;
    min-width: 20%;
    padding-right: 10px;
`;
export const MyLogSliderWrapper = styled.div`
    min-width: 20%;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-left: auto;
`;

export const RelativeDiv = styled.div`
    position: relative;
`;
export const NotificationButtonWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 16px;
    transform: translate(-50%, -50%);
    &--fade-out {
        opacity: 0;

        transition: opacity 0.4s;
        pointer-events: none;
    }
`;

export const LeftCell = styled.div`
    flex-basis: 200px;
    flex-grow: 3;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
`;
export const MiddleCell = styled.div`
    flex-basis: 130px;
    flex-grow: 2;
    max-width: 25%;
    margin-right: 5px;
    overflow: hidden;
`;
export const RightCell = styled.div`
    flex-basis: 10px;
    text-align: right;
`;

export const ImportLogRowWrapper = styled.div`
    margin-top: 16px;
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.shadows.initial};
    &:hover {
        box-shadow: ${({ theme }) => theme.shadows.hover};
        transition: box-shadow 0.2s;
    }
`;

export const ImportLogRowColor = styled.span`
    min-width: 5px;
    border-radius: 8px 0px 0px 8px;
`;

export const ImportLogRowColumns = styled.div`
    width: calc(100% - 5px);
`;

export const ImportLogRowNameWrapper = styled.div`
    font-weight: 700;
`;
export const ImportLogRowEmailWrapper = styled.div`
    font-size: 12px;
    color: #7f8ea4;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ImportLogRowDetailsWrapper = styled.div`
    padding: 15px;
    border-top: 1px solid ${({ theme }) => theme.colors.default.alpha};
    background-color: ${({ theme }) => theme.colors.theme.initial};
`;
export const ImportLogRowDetailsButtons = styled.div`
    margin-right: 10px;
    display: inline-block;
`;
