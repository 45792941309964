import { FC, useEffect, useState } from 'react';

import { List, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import InfiniteScroll from 'react-infinite-scroller';
import { useInfiniteQuery, useMutation } from 'react-query';
import { useSearchParams } from 'react-router';

import { CategoryData } from 'app/api/types/categories';

import api from 'app/api/v2/api_calls';

import AdminPage from 'admin/common/components/templates/AdminPage';
import { CATEGORIES } from 'admin/common/data/queryKeysConstants';

import { CategoryExportButton } from './components/CategoryExportButton';

import { CategoryListingCard } from './components/CategoryListingCard/CategoryListingCard';
import { CategoryListingEmpty } from './components/CategoryListingEmpty/CategoryListingEmpty';
import { buildQueryParams, formatCategoryData } from './helpers';

const getTitleFromQueryParams = (queryParams: Record<string, any>) => {
    if (queryParams.get('missing_mappings')) return 'admin:page_categories_missing_mapping__title';
    if (queryParams.get('state')) return 'admin:page_categories_obsolete__title';
    return 'admin:page_categories_all__title';
};

export const CategoryList: FC = () => {
    const { t } = useTranslation();
    const [categories, setCategories] = useState<CategoryData[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [queryParams, setQueryParams] = useState({});
    const [currentSearch, setCurrentSearch] = useState(searchParams.get('search_query') || '');
    const [displaySearchBar, setDisplaySearchBar] = useState(!!currentSearch);

    const categoryColumns = [
        { label: t('admin_categories_tableheaders_name') },
        { label: t('admin_categories_tableheaders_gcid') },
        { label: t('admin_categories_tableheaders_status'), width: '60px' },
        { label: t('admin_categories_tableheaders_businesscount'), width: '110px' },
        { width: '42px' },
    ];

    const toggleSearchBar = () => {
        if (!displaySearchBar || (displaySearchBar && (!currentSearch || !currentSearch.length))) {
            setDisplaySearchBar(!displaySearchBar);
        }
    };
    const updateCurrentSearch = (value?: string) => {
        setCurrentSearch(value || '');
        if (!searchParams.has('order_by')) {
            searchParams.set('order_by', '-impacted_businesses');
        }
        if (value) {
            searchParams.set('search_query', value);
        } else {
            searchParams.delete('search_query');
        }
        setSearchParams(searchParams);
    };

    const { mutate } = useMutation(() => api.categories.exportCategories(queryParams), {
        onError: () => {
            toast.error(
                t('admin:page_category__export_error_toast__title'),
                t('admin:page_category__export_error_toast__content'),
            );
        },
        onSuccess: () => {
            toast.success(
                t('admin:page_category__export_toast__title'),
                t('admin:page_category__export_toast__content'),
            );
        },
    });

    const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery(
        [CATEGORIES, queryParams],
        ({ pageParam = 1 }) => api.categories.getCategoriesDetails(queryParams, pageParam),
        {
            getNextPageParam: lastPage =>
                lastPage.max_page > lastPage.page ? lastPage.page + 1 : undefined,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
        },
    );

    useEffect(() => {
        setCategories(data?.pages?.map(({ categories }) => categories)?.flat() || []);
    }, [data]);

    useEffect(() => {
        setCategories([]);
        setQueryParams(buildQueryParams(searchParams, currentSearch));
    }, [searchParams]);

    return (
        <AdminPage
            dataTrackId="page_category_list"
            title={t(getTitleFromQueryParams(searchParams))}
            displaySearchBar={displaySearchBar}
            toggleSearchBar={toggleSearchBar}
            currentSearch={currentSearch}
            updateCurrentSearch={updateCurrentSearch}
            buttons={
                <CategoryExportButton
                    onClick={mutate}
                    disabled={!categories.length && !isFetching}
                />
            }
        >
            <InfiniteScroll
                loadMore={() => {
                    if (!isFetching) fetchNextPage();
                }}
                hasMore={hasNextPage}
                useWindow={false}
                threshold={50}
                getScrollParent={() => document.getElementById('page_wrapper')}
            >
                <List
                    columns={categoryColumns}
                    showHeader
                    loading={!categories.length && isFetching}
                >
                    {categories &&
                        categories.map((category, index) => (
                            <CategoryListingCard
                                key={`${category.gmb_id}--${category.id}__${index}`}
                                category={formatCategoryData(category)}
                            />
                        ))}
                    {!categories.length && !isFetching && <CategoryListingEmpty />}
                </List>
            </InfiniteScroll>
        </AdminPage>
    );
};
