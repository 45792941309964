import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

export const DiffusionErrorList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-inline-start: 0px;

    & > * + * {
        margin-block-start: 16px;
    }
`;

export const DiffusionCleanerStartPageContainer = styled.div`
    display: flex;
    align-items: center;
    margin: auto;
    height: 100%;
    max-width: 372px;
`;
export const DiffusionCleanerStartPageContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
`;

export const DiffusionCleanerFiltersWrapper = styled(Stack)`
    margin-bottom: 16px;
`;
