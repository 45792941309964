import { css } from '@emotion/react';
import styled from '@emotion/styled';

type PageWrapperProps = {
    isSideMenuOpen: boolean;
};

type PageHeaderProps = {
    haveDescription: boolean;
};

type PageFormRowProps = {
    marginBottom?: number;
};

export const PageWrapper = styled.div<PageWrapperProps>`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;

    margin-left: -16px;
    border-radius: 0px;
    box-shadow:
        -8px 0px 10px rgba(0, 0, 0, 0.0502896),
        -1px 0px 0px rgba(0, 0, 0, 0.04);
    padding: 48px 40px;

    background-color: #ffffff;

    transition: border-radius 0.3s;
    overflow-y: scroll;

    ${props =>
        props.isSideMenuOpen &&
        css`
            border-radius: 10px 0px 0px 10px;
        `}
`;

export const PageHeader = styled.div<PageHeaderProps>`
    display: flex;
    justify-content: space-between;

    margin: 0px 0px 16px -8px;

    min-height: 44px;

    ${props =>
        props.haveDescription &&
        css`
            margin: 0px 0px 32px -8px;
        `}
`;

export const PageHeaderLeft = styled.div`
    display: flex;
    align-items: center;
`;

export const PageHeaderTitle = styled.div`
    margin: 2px 0px 0px 8px;
    display: flex;
    justify-content: center;

    font-size: 30px;
    font-weight: bold;
    line-height: 35px;
`;
export const PageHeaderTitleContent = styled.div`
    display: flex;
    align-items: center;
`;
export const SearchBarWrapper = styled.div`
    width: 40vw;
`;

export const SearchIconWrapper = styled.div`
    margin-left: 10px;
`;

export const PageHeaderButtons = styled.div`
    display: flex;
    gap: 8px;

    margin-left: 8px;
`;

export const PageDescription = styled.div`
    margin-bottom: 16px;
    align-items: center;
    display: flex;

    color: ${({ theme }) => theme.colors.secondary.initial};
    font-weight: 700;
    line-height: 17px;
`;

export const PageContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const PageFormRow = styled.div<PageFormRowProps>`
    display: flex;
    column-gap: 24px;
    align-items: start;
    & > * {
        flex-grow: 1;
    }

    > *:nth-of-type(n + 1) {
        flex: 1;
        max-width: 100%;
    }

    &:not(:last-child) {
        margin-bottom: 24px;

        ${props =>
            props.marginBottom &&
            css`
                margin-bottom: ${props.marginBottom}px;
            `}
    }
`;

export const PageFormSubtitle = styled.div`
    margin-bottom: 16px;

    font-weight: bold;
    font-size: 16px;
`;
