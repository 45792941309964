import { FC } from 'react';

import { Option, SingleSelect } from '@partoohub/ui';
import { useQuery } from 'react-query';

import User from 'app/api/v2/api_calls/userApiCalls';

import { AVAILABLE_ROLES } from 'admin/common/data/queryKeysConstants';

type Props = {
    role?: Option;
    placeholder: string;
    setRole: (selectedItems?: Option) => void;
    dataTrackId: string;
    filterRole?: (role: string) => boolean;
};

export const NewRoleSelect: FC<Props> = ({
    role,
    placeholder,
    setRole,
    dataTrackId,
    filterRole = () => true,
}: Props) => {
    const { data } = useQuery(
        [AVAILABLE_ROLES],
        async () => {
            const roleList = await User.getAvailableRoles();
            const options = roleList.roles.filter(filterRole).map(r => ({
                value: r,
                name: r,
                label: r.charAt(0).toUpperCase() + r.slice(1).toLowerCase().replace('_', ' '),
            }));
            const sections = [{ options: options }];

            return sections;
        },
        {
            staleTime: Infinity,
        },
    );
    const sections = data || [];

    return (
        <SingleSelect
            dataTrackId={dataTrackId}
            sections={sections}
            selectedValue={role}
            onChange={setRole}
            label={placeholder}
            maxHeight={300}
        />
    );
};
