export enum UserListQueryKeys {
    QUERY = 'query',
    STATUS = 'status',
    ROLE = 'role',
    ASSIGNED_BUSINESS = 'has_assigned_businesses',
    USER_EDIT = 'user_edit',
    USER_DELETE = 'user_delete',
    USER_INVITE = 'user_invite',
}

export enum UserStatus {
    ACTIVE = 'active',
    INVITED = 'invited',
}

export enum UserAssignedBusiness {
    WITH_ASSIGNED_BUSINESS = 'true',
    WITHOUT_ASSIGNED_BUSINESS = 'false',
}

export enum Products {
    PRESENCE_MANAGEMENT = 'presence_management',
    REVIEW_MANAGEMENT = 'review_management',
    REVIEW_BOOSTER = 'review_booster',
    MESSAGES = 'business_message',
}

export const USER_ID_CREATE = 'create';
