import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Outlet } from 'react-router';

import { useIsSideMenuOpen } from 'admin/common/contexts/isSideMenuOpenContext';
import {
    COMPETITIVE_BENCHMARK_PATH,
    DEMO_CREATOR_PATH,
    DIFFUSION_CLEANER_PATH,
    DUPLICATES_CLIENT_LOCATION_SEARCH_TOOL_PATH,
    FEEDBACK_MANAGEMENT_GENERIC_PROMPT_PATH,
    FEEDBACK_MANAGEMENT_ORGANIZATION_SETTINGS_PATH,
    GENERATE_FAKE_DATA_PATH,
    OVERRIDING_TOOL_PATH,
    PROSPECTS_LOCATION_SEARCH_TOOL_PATH,
    REVIEW_TOOL_PATH,
} from 'admin/common/data/routeIds';
import env from 'admin/common/utils/getEnvironment';

import { SideMenuContent, SideMenuTitle, SideMenuWrapper } from './Menus.styled';
import MenuLink from '../MenuLink';
import Sublink from '../Sublink';

const ToolsMenu: FC = () => {
    const { t } = useTranslation();
    const { isSideMenuOpen } = useIsSideMenuOpen();

    return (
        <>
            <SideMenuWrapper open={isSideMenuOpen}>
                <SideMenuTitle>{t('admin:side_menu_tools__title')}</SideMenuTitle>

                <SideMenuContent>
                    {!env.isProd() && !env.isPreprod() && (
                        <MenuLink
                            to={DEMO_CREATOR_PATH}
                            name={t('admin:side_menu_tools__section__demo_creator')}
                        />
                    )}
                    <MenuLink
                        to={DIFFUSION_CLEANER_PATH}
                        name={<>{t('admin:side_menu_tools__section__diffusion_cleaner')}</>}
                    />
                    <MenuLink
                        to={OVERRIDING_TOOL_PATH}
                        name={t('admin:side_menu_tools__section__overriding_state')}
                    />
                    <MenuLink
                        to={REVIEW_TOOL_PATH}
                        name={t('admin:side_menu_tools__section__reviews_treatment')}
                    />
                    <MenuLink
                        to={PROSPECTS_LOCATION_SEARCH_TOOL_PATH}
                        name={t('admin:side_menu_tools__section__location_search')}
                        relatedPaths={[
                            PROSPECTS_LOCATION_SEARCH_TOOL_PATH,
                            DUPLICATES_CLIENT_LOCATION_SEARCH_TOOL_PATH,
                        ]}
                    >
                        <Sublink
                            to={PROSPECTS_LOCATION_SEARCH_TOOL_PATH}
                            name={t(
                                'admin:side_menu_tools__section_location_search__prospects_listings',
                            )}
                        />
                        <Sublink
                            to={DUPLICATES_CLIENT_LOCATION_SEARCH_TOOL_PATH}
                            name={t(
                                'admin:side_menu_tools__section_location_search__clients_duplicates',
                            )}
                        />
                    </MenuLink>
                    {!env.isProd() && (
                        <MenuLink
                            to={GENERATE_FAKE_DATA_PATH}
                            name={t('admin:side_menu_tools__section__generate_fake_data')}
                        />
                    )}
                    <MenuLink
                        to={FEEDBACK_MANAGEMENT_ORGANIZATION_SETTINGS_PATH}
                        name={t('admin:side_menu_tools__section__feedback_management')}
                        relatedPaths={[
                            FEEDBACK_MANAGEMENT_ORGANIZATION_SETTINGS_PATH,
                            FEEDBACK_MANAGEMENT_GENERIC_PROMPT_PATH,
                        ]}
                    >
                        <Sublink
                            to={FEEDBACK_MANAGEMENT_ORGANIZATION_SETTINGS_PATH}
                            name={t(
                                'admin:side_menu_tools__section__feedback_management__organization_settings',
                            )}
                        />
                        <Sublink
                            to={FEEDBACK_MANAGEMENT_GENERIC_PROMPT_PATH}
                            name={t(
                                'admin:side_menu_tools__section__feedback_management__generic_prompt',
                            )}
                        />
                    </MenuLink>
                    <MenuLink
                        to={COMPETITIVE_BENCHMARK_PATH}
                        name={t('admin:side_menu_tools__section__competitive_benchmark')}
                    />
                </SideMenuContent>
            </SideMenuWrapper>
            <Outlet />
        </>
    );
};

export default ToolsMenu;
