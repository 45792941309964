import { useState } from 'react';

import { DynamicFilters, PartialFilters } from '@partoohub/modular-components';
import { useTranslation } from 'react-i18next';

import { DiffusionCleanerCountFilters } from 'admin/common/api/types/diffusion_cleaner';

import { useFilterSchema } from './useFilterSchema';

type DiffusionCleanerFiltersProps = {
    disabled?: boolean;
    diffusionCleanerfilters: DiffusionCleanerCountFilters;
    setDiffusionCleanerFilters: React.Dispatch<React.SetStateAction<DiffusionCleanerCountFilters>>;
};

export const DiffusionCleanerFilters = ({
    disabled,
    diffusionCleanerfilters,
    setDiffusionCleanerFilters,
}: DiffusionCleanerFiltersProps) => {
    const { t } = useTranslation();
    const { schema, filters, setFilters } = useFilterSchema({
        diffusionCleanerfilters,
        setDiffusionCleanerFilters,
    });

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    return (
        <DynamicFilters
            schema={schema}
            filters={filters as PartialFilters}
            onApply={setFilters}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            resetText={t('reset')}
            applyText={t('confirm')}
            title={t('filters')}
            buttonText={t('filters')}
            disabled={disabled}
        />
    );
};
