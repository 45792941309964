import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';

import { UseInfiniteQueryResult } from 'react-query/types/react/types';

import { FuzzySearchOrgsReceivedData, SearchOrgParams, V2OrgData } from 'app/api/types/org';

import { organizationApiClient } from 'admin/api/apiResources';
import { ORGANIZATIONS } from 'admin/common/data/queryKeysConstants';

export const useOrgs = (
    filters: SearchOrgParams = {},
    options: UseInfiniteQueryOptions<FuzzySearchOrgsReceivedData> = {},
): [UseInfiniteQueryResult<FuzzySearchOrgsReceivedData>, Array<V2OrgData> | undefined] => {
    const query = useInfiniteQuery(
        [ORGANIZATIONS, { filters }],
        ({ pageParam = 1 }) =>
            organizationApiClient.searchOrgs({
                ...filters,
                page: pageParam,
            }),
        {
            getNextPageParam: prevPage =>
                prevPage.page < prevPage.max_page ? prevPage.page + 1 : undefined,
            ...options,
        },
    );

    const orgs = query.data?.pages.map(({ orgs }) => orgs)?.flat();

    // TODO: return object instead of array
    return [query, orgs];
};
