import {
    FuzzySearchProviderReceivedData,
    ProviderType,
    SearchProviderParams,
} from '@partoohub/api-client';
import { useInfiniteQuery } from 'react-query';
import { UseInfiniteQueryResult } from 'react-query/types/react/types';

import { providerApiClient } from 'admin/api/apiResources';
import { PROVIDERS } from 'admin/common/data/queryKeysConstants';

export const useProviders = (
    filters: SearchProviderParams = { query: '' },
    options = {},
): [UseInfiniteQueryResult<FuzzySearchProviderReceivedData>, Array<ProviderType> | undefined] => {
    const query = useInfiniteQuery(
        [PROVIDERS, filters],
        ({ pageParam = 1 }) => providerApiClient.searchProviders(filters.query, pageParam),
        {
            getNextPageParam: prevPage =>
                prevPage.page < prevPage.max_page ? prevPage.page + 1 : undefined,
            ...options,
        },
    );

    const providers = query.data?.pages.map(({ providers }) => providers)?.flat();

    // TODO: return object instead of array
    return [query, providers];
};
