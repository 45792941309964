import { GetSectionsParams, GetSectionsReceivedData } from '@partoohub/api-client';
import { UseInfiniteQueryOptions, UseInfiniteQueryResult } from 'react-query';

import { GroupSectionList } from 'app/api/types/groupsV2';
import useSelectedOrgBusinessModal from 'app/common/components/businessModal/hooks/privateHooks/useSelectedOrgBusinessModal';
import useGroupsV2 from 'app/common/hooks/queries/useGroupsV2';

export default function useOrgFilteredGroupsV2(
    searchFilters: GetSectionsParams = {},
    options: UseInfiniteQueryOptions<GetSectionsReceivedData> = {},
): {
    query: UseInfiniteQueryResult<GetSectionsReceivedData>;
    sections: GroupSectionList | undefined;
} {
    const { searchOrgId, modalSelectedOrgId } = useSelectedOrgBusinessModal();

    const params = {
        org_id: searchOrgId ?? modalSelectedOrgId,
        ...searchFilters,
    };

    return useGroupsV2(params, options);
}
