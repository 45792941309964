import styled from '@emotion/styled';

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: right;
    align-items: center;
`;
export const Card = styled.div`
    display: flex;
    padding: 24px;
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    border-radius: 16px;
`;

export const Side = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
`;

export const QrCodeSide = styled(Side)`
    padding-right: 24px;
`;
export const QrCodeWrapper = styled.div`
    width: 100%;
    text-align: center;
`;

export const UrlSide = styled(Side)`
    padding-left: 24px;
    border-left: 1px solid ${({ theme }) => theme.colors.default.alpha};
`;
export const UrlWrapper = styled.div`
    width: 100%;
`;

export const QrCodeTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 8px;
`;

export const CardBoldText = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
`;

export const CardLightText = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #7f8ea4;
`;
