import { useSearchParams } from 'react-router';

type UseSearchParam = [string | null, (value?: string) => void];

export const useSearchParam = (queryKey: string, pushHistory = false): UseSearchParam => {
    const [searchParams, setSearchParams] = useSearchParams();
    const setParam = (value?: string) =>
        setSearchParams(
            prev => {
                if (value === undefined) {
                    prev.delete(queryKey);
                    return prev;
                }

                prev.set(queryKey, value);
                return prev;
            },
            { replace: !pushHistory },
        );

    return [searchParams.get(queryKey), setParam];
};
