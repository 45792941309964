import styled from '@emotion/styled';

import { IconButton } from '@partoohub/ui';

import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

export const StyledFullScreen = styled.div`
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100dvh;
    background: linear-gradient(
        107.6deg,
        #e6f1f7 0%,
        #f3f3fc 23.96%,
        #f6f4f1 50%,
        #f6f2ec 73.96%,
        #f6f8fc 100%
    );
`;

export const StyledIconButton = styled(IconButton)`
    position: absolute !important;
    top: 16px;
    right: 16px;
`;

export const StyledFullScreenModal = styled(FullScreenModal)`
    background-color: red;
`;

export const RightPart = styled.div`
    display: flex;
    width: 40%;
    max-width: 500px;
    background-size: cover;
    overflow: hidden;
`;
