import { getQueryObjectFromObject } from '@partoohub/utils';

import { SuccessResponse } from 'app/api/types';
import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';
import { generateDelightMessages } from 'app/reviewManagement/messaging/utils/generateDelightMessages';
import {
    ConversationStats,
    ConversationsData,
    MessagingInfos,
    PostMessageData,
} from 'app/states/messaging';
import {
    MESSAGE_CONTENT_TYPE,
    MessageContentType,
    StatusFilterOption,
} from 'app/states/messaging/dataTypes';

const CONVERSATIONS_RESOURCE_URI = 'conversations';
const MESSAGES_RESOURCE_URI = 'messages';
const CONVERSATIONS_STATS_RESOURCE_URI = `${CONVERSATIONS_RESOURCE_URI}/stats`;

class Messaging extends ApiResourceBaseClass {
    static getConversation(conversationId: number): Promise<Record<string, any>> {
        return Messaging.get(`${CONVERSATIONS_RESOURCE_URI}/${conversationId}`).then(
            ({ data }) => data,
        );
    }

    static getConversations(
        queryFilter: Record<string, any> = {},
        cursor: string | null = null,
        status: StatusFilterOption,
        assignedTo: string | null,
    ): Promise<ConversationsData> {
        const data = {
            ...getQueryObjectFromObject(queryFilter),
            cursor,
            status: status,
            assigned_to: assignedTo,
        };
        return Messaging.get(`${CONVERSATIONS_RESOURCE_URI}`, data).then(({ data }) => {
            return data;
        });
    }

    static setConversationRead(conversationId: number): Promise<Record<string, any>> {
        const route = `${CONVERSATIONS_RESOURCE_URI}/${conversationId}/read?partoo_ui=true`;
        return Messaging.post(route).then(({ data }) => data);
    }

    static postMessage(
        conversationId: number,
        message: string,
        date: string,
        contentType: MessageContentType,
        templateId: string | null,
    ): Promise<PostMessageData> {
        if (contentType === MESSAGE_CONTENT_TYPE.TEXT) {
            const route = `${MESSAGES_RESOURCE_URI}?partoo_ui=true`;
            const data = {
                conversation_id: conversationId,
                content_type: MESSAGE_CONTENT_TYPE.TEXT,
                content: message,
                date,
                ...(templateId && { template_id: templateId }),
            };
            return Messaging.post(route, data).then(({ data }) => data);
        }

        const route = `${MESSAGES_RESOURCE_URI}?partoo_ui=true`;
        const headers = { 'Content-Type': 'multipart/form-data' };
        const formData = new FormData();
        formData.append('conversation_id', conversationId.toString());
        formData.append('content_type', MESSAGE_CONTENT_TYPE.IMAGE);
        formData.append('image', message);
        formData.append('date', date);
        return Messaging.post(route, formData, headers).then(({ data }) => data);
    }

    static getMessages(
        conversationId: number,
        cursor: string | null = null,
        withMetadata: boolean | null = null,
    ): Promise<Record<string, any>> {
        if (conversationId === -1)
            return new Promise(resolve => {
                const messages = generateDelightMessages();
                resolve({ next_cursor: null, count: messages.length, messages });
            });

        const route = MESSAGES_RESOURCE_URI;
        const data = {
            conversation_id: conversationId,
            cursor,
            with_metadata: withMetadata,
            partoo_ui: true,
        };
        return Messaging.get(route, data).then(({ data }) => data);
    }

    static retryToSendMessage(messageId: number): Promise<Record<string, any>> {
        const route = `${MESSAGES_RESOURCE_URI}/${messageId}/retry`;
        const data = { message_id: messageId };
        return Messaging.post(route, data).then(({ data }) => data);
    }

    static getConversationsStats(
        queryFilter: Record<string, any> = {},
    ): Promise<ConversationStats> {
        const route = `${CONVERSATIONS_STATS_RESOURCE_URI}`;
        const data = { partoo_ui: true, ...getQueryObjectFromObject(queryFilter) };
        return Messaging.get(route, data).then(({ data }) => data);
    }

    static infos(): Promise<MessagingInfos> {
        return Messaging.get('messaging/infos').then(({ data }) => data);
    }

    static updateConversationStatus(
        conversationId: number,
        queryFilter: Record<string, any>,
    ): Promise<SuccessResponse> {
        const route = `${CONVERSATIONS_RESOURCE_URI}/${conversationId}`;
        const data = { status: queryFilter.status };
        return Messaging.patch(route, data).then(({ data }) => data);
    }

    static updateConversationAssignedUser(
        conversationId: number,
        queryFilter: Record<string, any>,
    ): Promise<SuccessResponse> {
        const route = `${CONVERSATIONS_RESOURCE_URI}/${conversationId}`;
        const data = { assigned_user_id: queryFilter.assignedUserId };
        return Messaging.patch(route, data).then(({ data }) => data);
    }
}

export default Messaging;
