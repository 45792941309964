import { FC } from 'react';

import { Option, SingleSelect } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { UserStatus } from 'admin/common/data/enums';

type Props = {
    status?: Option;
    placeholder: string;
    setStatus: (selectedItems?: Option) => void;
    dataTrackId: string;
};

const UserStatusSelect: FC<Props> = ({ status, placeholder, setStatus, dataTrackId }: Props) => {
    const { t } = useTranslation();
    const options: Option[] = [
        {
            value: UserStatus.INVITED,
            label: t(UserStatus.INVITED),
            name: UserStatus.INVITED,
        },
        {
            value: UserStatus.ACTIVE,
            label: t(UserStatus.ACTIVE),
            name: UserStatus.ACTIVE,
        },
    ];
    const sections = [{ options: options }];

    return (
        <SingleSelect
            dataTrackId={dataTrackId}
            sections={sections}
            selectedValue={status}
            onChange={setStatus}
            label={placeholder}
            maxHeight={300}
        />
    );
};

export default UserStatusSelect;
