import { useCallback } from 'react';

import { getQueryStringFromObject } from '@partoohub/utils';
import { useLocation, useNavigate } from 'react-router';

import { useSearchParam } from 'app/common/hooks/useSearchParam';

/**
 * Works like a useState but use the URL as the state. Must be string value *
 * @param queryKey the param to retrieve/write in the url
 * @param pushHistory to push instead of replace in the history, use for handling back button in the navigator
 */
export const useStateQueryParams = <T extends string>(
    queryKey: string,
    pushHistory = false,
): [T, (arg0: T) => void] => {
    const location = useLocation();
    const navigate = useNavigate();
    const value = useSearchParam(queryKey) as T;
    const setValue = useCallback(
        (value: unknown) => {
            const queryString =
                value === '' ? `${queryKey}=` : getQueryStringFromObject({ [queryKey]: value });
            navigate(`${location.pathname}?${queryString}`, {
                replace: !pushHistory,
                state: { isOnApp: true },
            });
        },
        [history, location, queryKey],
    );

    return [value, setValue];
};
