import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';
import { Lead, LeadFormFields, LeadsData } from 'app/pages/settings/Chatbot/type/lead';

export class Leads extends ApiResourceBaseClass {
    static getLeadFormFields(lead_form_id?: number): Promise<LeadFormFields> {
        return Leads.get(`lead_forms/${lead_form_id}/fields`).then(({ data }) => data);
    }

    static updateLeadFormFields(lead_form_id: number, payload): Promise<LeadFormFields> {
        return Leads.patch(`lead_forms/${lead_form_id}/fields`, payload).then(({ data }) => data);
    }

    static getLead(lead_id?: number): Promise<Lead> {
        return Leads.get(`leads/${lead_id}`).then(({ data }) => data);
    }

    static getLeads(lead_form_id: number): Promise<LeadsData> {
        return Leads.get('leads', { lead_form_id }).then(({ data }) => data);
    }
}
