import styled from '@emotion/styled';

export const Body = styled('div')`
    display: flex;
    border-bottom: solid 1px ${({ theme }) => theme.colors.default.alpha};
    align-items: center;
    height: 70px;
`;

export const LeftIcon = styled('i')`
    padding-left: 24px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    cursor: grabbing;
`;

export const BodyContent = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    width: 100%;
    min-width: 0;
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 14px;
    line-height: 17px;
`;

export const BodyOptionalElements = styled('div')`
    display: flex;
    align-items: baseline;
    min-width: fit-content;
`;

export const BodyOptionalLabel = styled('div')`
    padding: 0 24px;
    font-weight: bold;
`;

export const BodyComponent = styled('div')`
    padding-left: 24px;
`;

export const BodyMain = styled('div')`
    min-width: 0px;
`;

export const BodyLabel = styled('div')`
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #333333;
`;

export const BodySubLabel = styled('div')`
    font-weight: bold;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const BodyDescription = styled('div')`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
