import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import { greyColorObject } from '@partoohub/branding';
import {
    Button,
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    Separator,
    Stack,
    Switch,
    Text,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { KeywordAdminData } from 'admin/common/api/types/competitiveBenchmark';

import {
    ExpandableFullWidth,
    KeywordContentContainer,
    StackFullWidth,
} from './KeywordContent.styled';
import { useDeleteKeyword } from '../../hooks/useDeleteKeyword';
import { useOrgIdParsed } from '../../hooks/useOrgIdParsed';
import { useToggleKeyword } from '../../hooks/useToggleKeyword';
import { CompetitorList } from '../CompetitorList';
import { DeleteModal } from '../DeleteModal';
import { EditCompetitorModal } from '../EditCompetitorModal';

type Props = {
    keyword: KeywordAdminData;
};

export const KeywordContent = ({ keyword }: Props) => {
    const { t } = useTranslation();
    const [isExpand, setIsExpand] = useState(true);
    const [createCompetitorModalOpened, setCreateCompetitorModalOpened] = useState(false);
    const [deleteKeywordModalOpened, setDeleteKeywordModalOpened] = useState(false);
    const orgId = useOrgIdParsed();
    const { mutate } = useToggleKeyword();
    const {
        mutate: deleteKeyword,
        isSuccess: deletionSuccess,
        reset: deletionReset,
    } = useDeleteKeyword();

    useEffect(() => {
        if (deletionSuccess) {
            setDeleteKeywordModalOpened(false);
            deletionReset();
        }
    }, [deletionSuccess]);

    return (
        <>
            <KeywordContentContainer>
                <StackFullWidth direction="row" justifyContent="space-between">
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap="8px"
                        justifyContent="space-between"
                    >
                        <Switch
                            checked={keyword.active}
                            dataTrackId="keyword-section-toggle-keyword"
                            name="keyword"
                            onChange={event =>
                                mutate({ id: event.target.id, active: event.target.checked, orgId })
                            }
                            id={keyword.id}
                            disabled={keyword.locked}
                            tooltip={keyword.locked ? t('admin:keyword_locked') : undefined}
                        />
                        <Text
                            variant="heading6"
                            as="span"
                            css={css`
                                text-transform: capitalize;
                            `}
                        >
                            {keyword.keyword}
                        </Text>
                        <IconButton
                            dataTrackId="keyword-section-expand-container"
                            appearance="outlined"
                            icon={[
                                isExpand
                                    ? FontAwesomeIconsPartooUsed.faChevronDown
                                    : FontAwesomeIconsPartooUsed.faChevronRight,
                                IconPrefix.REGULAR,
                            ]}
                            size="small"
                            onClick={() => setIsExpand(!isExpand)}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap="8px"
                        justifyContent="space-between"
                    >
                        <Button
                            dataTrackId="button"
                            icon={[FontAwesomeIconsPartooUsed.faAdd, IconPrefix.REGULAR]}
                            onClick={() => setCreateCompetitorModalOpened(true)}
                            variant="primary"
                            appearance="text"
                            disabled={keyword.competitors.length >= 12}
                            tooltip={
                                keyword.competitors.length >= 12
                                    ? t('admin:limit_competitors_text')
                                    : undefined
                            }
                        >
                            {t('admin:add_competitor')}
                        </Button>
                        <IconButton
                            dataTrackId="icon-delete-button"
                            icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                            appearance="outlined"
                            variant="danger"
                            disabled={!keyword.deletable}
                            tooltip={!keyword.deletable ? 'admin:keyword_not_deletable' : undefined}
                            onClick={() => setDeleteKeywordModalOpened(true)}
                        />
                    </Stack>
                </StackFullWidth>
                <Separator color={greyColorObject.initial} />
                <ExpandableFullWidth opened={isExpand}>
                    <CompetitorList keyword={keyword} />
                </ExpandableFullWidth>
            </KeywordContentContainer>
            <EditCompetitorModal
                onClose={() => setCreateCompetitorModalOpened(false)}
                opened={createCompetitorModalOpened}
                keywordId={keyword.id}
            />
            <DeleteModal
                title={t('admin:delete_keyword_modal_title')}
                onClose={() => setDeleteKeywordModalOpened(false)}
                opened={deleteKeywordModalOpened}
                onConfirm={() => deleteKeyword({ orgId: keyword.partoo_org_id, id: keyword.id })}
            />
        </>
    );
};
