/* Note: This file will be removed when ChatPreferences is deleted. */
import {
    MessagingSettingsStats,
    PaginatedBmSettings,
} from 'app/api/types/business_message_settings';
import { MessagingSetting } from 'app/api/types/business_message_settings';
import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';
import formatObjectKeysToSnakeCase from 'app/businessEditV2/oldFromEditV1/services/formatObjectKeysToSnakeCase';

class MessagingSettings extends ApiResourceBaseClass {
    static bmSettings(pageParam: number, business_id?: string): Promise<PaginatedBmSettings> {
        return MessagingSettings.get('messaging/settings', {
            page: pageParam,
            business__in: business_id,
            partoo_ui: true,
        }).then(({ data }) => data);
    }

    static update(id: number, payload: Partial<MessagingSetting>): Promise<any> {
        const postPayload = formatObjectKeysToSnakeCase(payload);
        if ('uses_business_hours' in postPayload)
            postPayload.uses_business_hours = !payload.uses_business_hours;
        return MessagingSettings.post(`messaging/settings/${id}?partoo_ui=true`, postPayload).then(
            ({ data }) => data,
        );
    }

    static messagingSettingsStats(): Promise<MessagingSettingsStats> {
        return MessagingSettings.get('messaging/settings/stats').then(({ data }) => data);
    }
}

export default MessagingSettings;
