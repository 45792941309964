import { getQueryStringFromObject, parseQuery } from '@partoohub/utils';
import { format, isValid, parseISO } from 'date-fns';

import {
    BUSINESSES_CITIES,
    BUSINESSES_GROUPS,
    BUSINESSES_ORGS,
    BUSINESSES_QUERIES,
    BUSINESSES_SELECT_ALL,
    EXCLUDED_BUSINESSES,
    INCLUDED_BUSINESSES,
} from 'app/common/data/routeIds';

export const parseNumberArray = (value: string) =>
    parseStringArray(value)
        .map(s => parseInt(s, 10))
        .filter(n => !Number.isNaN(n));

export const stringifyNumberArray = (value: Array<number>) => value.join(',');

export const parseStringArray = (value: string) => value.split(',').filter(Boolean);

export const stringifyStringArray = (value: Array<string>) => value.join(',');

export const identity = <T>(_: T) => _;

export const parseDateFns = (value: string) => {
    const parsedDate = parseISO(value);
    return isValid(parsedDate) ? parsedDate : null;
};

export const stringifyDateFns = (value: Date | null) => {
    return value !== null ? format(value, 'yyyy-MM-dd') : '';
};

export const businessParams = (): string => {
    /* Return date and business related params as string */
    const reservedParams: string[] = [
        BUSINESSES_SELECT_ALL,
        INCLUDED_BUSINESSES,
        EXCLUDED_BUSINESSES,
        BUSINESSES_QUERIES,
        BUSINESSES_CITIES,
        BUSINESSES_GROUPS,
        BUSINESSES_ORGS,
    ];
    const queryParamsObj = parseQuery(location.search);
    const filteredQueryParamsObj = Object.entries(queryParamsObj).reduce((acc, cur) => {
        if (reservedParams.indexOf(cur[0]) > -1) {
            acc[cur[0]] = cur[1];
        }
        return acc;
    }, {});
    return getQueryStringFromObject(filteredQueryParamsObj);
};
