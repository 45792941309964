import AdminApiResourceBaseClass from './baseAdminApiCalls';
import {
    CompetitorWithId,
    CreateNewCompetitorPayloadWithOrgId,
    CreateNewKeywordPayload,
    DeleteCompetitorPayload,
    DeleteKeywordPayload,
    KeywordAdminData,
    KeywordAdminDataRaw,
    ToggleKeywordPayload,
    UpdateCompetitorPayload,
} from '../types/competitiveBenchmark';

const RESOURCE_URI = 'competitive-benchmark';

export class CompetitiveBenchmark extends AdminApiResourceBaseClass {
    static getKeywordsFromOrg(orgId: number): Promise<KeywordAdminDataRaw> {
        return CompetitiveBenchmark.get(`${RESOURCE_URI}/keywords/${orgId}`).then(
            ({ data }) => data,
        );
    }

    static createNewKeyword(payload: CreateNewKeywordPayload): Promise<KeywordAdminData> {
        return CompetitiveBenchmark.post(`${RESOURCE_URI}/keywords/${payload.orgId}`, {
            keyword: payload.newKeyword,
        }).then(({ data }) => data);
    }

    static toggleKeyword(payload: ToggleKeywordPayload): Promise<KeywordAdminData> {
        return CompetitiveBenchmark.patch(`${RESOURCE_URI}/keywords/${payload.orgId}`, {
            id: payload.id,
            active: payload.active,
        }).then(({ data }) => data);
    }

    static deleteKeyword(payload: DeleteKeywordPayload): Promise<null> {
        return CompetitiveBenchmark.delete(`${RESOURCE_URI}/keywords/${payload.orgId}`, {
            id: payload.id,
        }).then(() => null);
    }

    static createCompetitor(
        payload: CreateNewCompetitorPayloadWithOrgId,
    ): Promise<CompetitorWithId> {
        const { orgId, keywordId, name, included, excluded } = payload;
        return CompetitiveBenchmark.post(`${RESOURCE_URI}/competitors`, {
            name,
            keyword_id: keywordId,
            org_id: orgId,
            included,
            excluded,
        }).then(({ data }) => data);
    }

    static deleteCompetitor(payload: DeleteCompetitorPayload): Promise<CompetitorWithId> {
        const { competitor } = payload;
        return CompetitiveBenchmark.delete(`${RESOURCE_URI}/competitors/${competitor.id}`).then(
            ({ data }) => data,
        );
    }

    static updateCompetitor(payload: UpdateCompetitorPayload): Promise<CompetitorWithId> {
        const { competitor } = payload;
        return CompetitiveBenchmark.put(
            `${RESOURCE_URI}/competitors/${competitor.id}`,
            competitor,
        ).then(({ data }) => data);
    }
}
