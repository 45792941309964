import styled from '@emotion/styled';

export const DailyReportRowWrapper = styled.div`
    margin-top: 16px;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.shadows.initial};
    &:hover {
        box-shadow: ${({ theme }) => theme.shadows.hover};
        transition: box-shadow 0.2s;
    }
`;

export const DailyReportDetailsWrapper = styled.div`
    padding: 24px;
    border-top: 1px solid ${({ theme }) => theme.colors.default.alpha};
`;
export const LeftCell = styled.div`
    flex-basis: 140px;
    flex-grow: 1;
    font-weight: 700;
`;
export const MiddleCell = styled.div`
    flex-basis: 90px;
    flex-grow: 2;
    font-weight: 700;
`;
export const RightCell = styled.div`
    flex-basis: 140px;
    text-align: right;
    display: flex;
`;
export const ArrowWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: #7f8ea4;
`;
export const IconContainer = styled.div`
    height: 40px;
    padding: 8px;
    display: inline-block;
    background: #f6f8fc;
    border-radius: 50%;
    margin-right: 16px;
    stroke: black;
`;

export const DailyReportTypeFilterWrapper = styled.div`
    display: inline-block;
    min-width: 30%;
`;

export const DailyReportIconWrapper = styled.div`
    display: inline-block;
    text-align: center;
    width: 20px;
`;

export const DailyReportDetailIconWrapper = styled(DailyReportIconWrapper)`
    margin-right: 5px;
`;

export const DailyReportDataUl = styled.div`
    width: 50%;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
`;
export const DailyReportDataLi = styled.li`
    &::marker {
        color: #0085f2;
    }
`;
