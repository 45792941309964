import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

import { useIsSideMenuOpen } from 'admin/common/contexts/isSideMenuOpenContext';
import { CONNECT_AS_PATH } from 'admin/common/data/routeIds';

import { SideMenuContent, SideMenuTitle, SideMenuWrapper } from './Menus.styled';
import MenuLink from '../MenuLink';

const ConnectAsMenu: FC = () => {
    const { t } = useTranslation();
    const { isSideMenuOpen } = useIsSideMenuOpen();

    return (
        <>
            <SideMenuWrapper open={isSideMenuOpen}>
                <SideMenuTitle>{t('admin:side_menu_connect_as__title')}</SideMenuTitle>

                <SideMenuContent>
                    <MenuLink
                        to={CONNECT_AS_PATH}
                        name={<>{t('admin:side_menu_connect_as__section_user__user')} </>}
                    />
                </SideMenuContent>
            </SideMenuWrapper>
            <Outlet />
        </>
    );
};

export default ConnectAsMenu;
