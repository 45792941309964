import { getQueryObjectFromObject } from '@partoohub/utils';

import {
    CursorPaginationParams,
    LiteralRating,
    Placeholder,
    ReceivedReviewData,
    ReplySuggestion,
    ReviewData,
    ReviewPermissions,
    ReviewQueryParams,
    ReviewStatsResponse,
    SearchTemplatesParams,
    UpdateReviewParams,
} from 'app/api/types/review';

import {
    PaginatedReplyTemplatesResponse,
    ReplyTemplateApi,
    SaveReplyTemplatePayload,
    TemplateHasAutoReplySettingsResponse,
} from 'app/api/types/reviewReplyTemplates';

import ApiResourceBaseClass from './baseApiCalls';
import { SuccessResponse } from '../../types';

const RESOURCE_URI = 'reviews';

class Review extends ApiResourceBaseClass {
    static async getReviewStats(queryParams: ReviewQueryParams): Promise<ReviewStatsResponse> {
        const { data } = await Review.get(
            `${RESOURCE_URI}/stats`,
            getQueryObjectFromObject(queryParams),
        );
        return data;
    }

    static async getReview(reviewId: number): Promise<ReviewData> {
        const { data } = await Review.get(`${RESOURCE_URI}/${reviewId}`);
        return data;
    }

    static async searchReviews(
        queryParams: ReviewQueryParams & CursorPaginationParams,
    ): Promise<ReceivedReviewData> {
        const { data } = await Review.get(`${RESOURCE_URI}`, {
            ...getQueryObjectFromObject(queryParams),
            ordering: '-update_date',
        });
        return data;
    }

    static async updateReview({ id, ...restQueryParams }: UpdateReviewParams): Promise<ReviewData> {
        const { data } = await Review.put(`${RESOURCE_URI}/${id}`, restQueryParams);
        return data;
    }

    static async downloadReviews(
        queryParams: ReviewQueryParams & { email: string },
    ): Promise<SuccessResponse> {
        const { data } = await Review.get(`${RESOURCE_URI}/download`, {
            ...getQueryObjectFromObject(queryParams),
            ordering: '-update_date',
        });
        return data;
    }

    static async searchTemplates(
        queryParams: SearchTemplatesParams,
    ): Promise<PaginatedReplyTemplatesResponse> {
        const { data } = await Review.get(
            `${RESOURCE_URI}/templates/search`,
            getQueryObjectFromObject(queryParams),
        );
        return data;
    }

    static async saveTemplate(queryParams: SaveReplyTemplatePayload): Promise<ReplyTemplateApi> {
        const { data } = await Review.post(`${RESOURCE_URI}/template`, queryParams);
        return data;
    }

    static async deleteReplyTemplate(templateId: string): Promise<SuccessResponse> {
        const { data } = await Review.delete(`${RESOURCE_URI}/template/${templateId}`);
        return data;
    }

    static async getTemplatePlaceholders(): Promise<Array<Placeholder>> {
        const { data } = await Review.get(`${RESOURCE_URI}/templates/placeholders`);
        return data;
    }

    static async getTemplateLanguages(rating?: LiteralRating): Promise<Array<string>> {
        const { data } = await Review.get(
            `${RESOURCE_URI}/templates/languages`,
            rating ? { [rating]: true } : {},
        );
        return data;
    }

    static async getTemplateHasAutoReplySettings(
        templateId: string,
    ): Promise<TemplateHasAutoReplySettingsResponse> {
        const { data } = await Review.get(
            `${RESOURCE_URI}/templates/${templateId}/has_auto_reply_settings`,
        );
        return data;
    }

    static async downloadTemplates(email: string): Promise<SuccessResponse> {
        const params = getQueryObjectFromObject({ email });
        const { data } = await Review.get(`${RESOURCE_URI}/templates/download`, params);
        return data;
    }

    static async uploadTemplates(file: Blob, email?: string): Promise<SuccessResponse> {
        const formData = new FormData();
        if (file) formData.append('file', file);
        if (email) formData.append('email', email);

        const { data } = await Review.post(`${RESOURCE_URI}/templates/upload`, formData);
        return data;
    }

    static async getPermissions(): Promise<ReviewPermissions> {
        const { data } = await Review.get(`${RESOURCE_URI}/permissions`);
        return data;
    }

    static async getReplySuggestion(reviewId: number, index: number): Promise<ReplySuggestion> {
        const { data } = await Review.get(`${RESOURCE_URI}/fetch-suggestion/${reviewId}/${index}`);
        return data;
    }

    static async reportSuggestion(suggestionId: number): Promise<{ [key: string]: string }> {
        const { data } = await Review.post(`${RESOURCE_URI}/report-suggestion`, {
            suggestion_id: suggestionId,
        });
        return data;
    }
}

export default Review;
