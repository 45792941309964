import React, { FC, ReactNode, useEffect, useState } from 'react';

import { NavLink, matchPath, useLocation } from 'react-router';

import { DropdownIcon, LinkStyled, LinkWrapper, SublinkGroup } from './MenuLink.styled';

type Props = {
    to: string;
    name: string | ReactNode;
    relatedPaths?: string[];
    // If any related path is accessed, it will open the SubLink dropdown.
    children?: ReactNode;
};

const MenuLink: FC<Props> = ({ to, name, relatedPaths, children }) => {
    const location = useLocation();

    const toggleIsSublinkOpen = () => {
        setIsSublinkOpen(!isSublinkOpen);
    };

    const isAnySublinkMatching = (path: string) => {
        if (relatedPaths === undefined) {
            return false;
        }
        for (const sublink of relatedPaths) if (matchPath(sublink, path)) return true;
        return false;
    };

    const [isSublinkOpen, setIsSublinkOpen] = useState(
        relatedPaths ? isAnySublinkMatching(location.pathname) : false,
    );

    useEffect(() => {
        if (!relatedPaths) return;

        setIsSublinkOpen(isAnySublinkMatching(location.pathname));
    }, [location]);

    return (
        <LinkWrapper>
            <LinkStyled>
                <NavLink
                    to={to}
                    className={({ isActive }) => (isActive && !children ? 'menu_link active' : '')}
                >
                    {name}
                </NavLink>
                {children && (
                    <DropdownIcon open={isSublinkOpen} onClick={toggleIsSublinkOpen}>
                        <i className="fa-solid fa-chevron-right" />
                    </DropdownIcon>
                )}
            </LinkStyled>
            <SublinkGroup open={isSublinkOpen}>{children}</SublinkGroup>
        </LinkWrapper>
    );
};

export default MenuLink;
