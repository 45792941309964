import { KeyboardEventHandler, useState } from 'react';

import { Button, Dialog, Modal, TextInput, TextInputType, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

interface ConfirmModalWithTextProps {
    /* Set how the confirm text should be validated:
     ** 'disable': Disable the confirm button until the input matches the expected text
     ** 'error': Always enable the confirm button but display an error if the input doesn't match the expected text
     */
    validationStyle?: 'disable' | 'error';

    confirmText: string;
    title: string;
    subTitle: string;
    inputTextLabel: string;
    opened: boolean;
    onConfirm: () => void;
    onClose: () => void;
    inputTextType?: TextInputType;
    errorNotice?: string;
}

/**
 * Confirm modal with an input field
 * The user is required to input the specified confirmText to be able to confirm
 */
export const ConfirmModalWithText = ({
    validationStyle = 'disable',
    opened,
    confirmText,
    title,
    subTitle,
    inputTextLabel,
    inputTextType,
    errorNotice,
    onConfirm,
    onClose,
}: ConfirmModalWithTextProps) => {
    const { t } = useTranslation();

    const [text, setText] = useState('');

    const closeModal = () => {
        setText('');
        onClose();
    };

    const confirmAndCloseModal = () => {
        if (validationStyle === 'error' && text !== confirmText) {
            toast.error('', errorNotice ?? t('error'));
        } else {
            onConfirm();
            closeModal();
        }
    };

    const onEnterKeyDown: KeyboardEventHandler = e => {
        if (e.key === 'Enter' && (validationStyle === 'error' || text === confirmText)) {
            confirmAndCloseModal();
        }
    };

    return (
        <Modal isOpen={opened} closeModal={closeModal}>
            <Dialog
                dataTrackId=""
                subTitle={subTitle}
                title={title}
                actions={[
                    <Button
                        dataTrackId="confirm_modal__cancel_button"
                        key="confirm_modal__cancel_button"
                        variant="secondary"
                        shape="cube"
                        size="large"
                        appearance="outlined"
                        full
                        onClick={closeModal}
                    >
                        {t('cancel')}
                    </Button>,
                    <Button
                        dataTrackId="confirm_modal__confirm_button"
                        key="confirm_modal__confirm_button"
                        disabled={!text || (validationStyle === 'disable' && text !== confirmText)}
                        variant="primary"
                        shape="cube"
                        size="large"
                        full
                        onClick={confirmAndCloseModal}
                    >
                        {t('confirm')}
                    </Button>,
                ]}
            >
                <TextInput
                    dataTrackId=""
                    autoFocus
                    type={inputTextType}
                    value={text}
                    onChange={setText}
                    label={inputTextLabel}
                    onKeyDown={onEnterKeyDown}
                />
            </Dialog>
        </Modal>
    );
};
