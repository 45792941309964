import { getQueryStringFromObject } from '@partoohub/utils';

import { ApiKeyPayload, ApiKeysData } from 'app/api/types/api_keys';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'api_keys';

class ApiKeys extends ApiResourceBaseClass {
    static getApiKeys(queryFilter: Record<string, any> = {}, page = 1): Promise<ApiKeysData> {
        const queryParams = getQueryStringFromObject({ ...queryFilter, page });
        return ApiKeys.get(`${RESOURCE_URI}?${queryParams}`).then(({ data }) => data);
    }

    static revokeApiKey(keyId: number): Promise<any> {
        return ApiKeys.post(`${RESOURCE_URI}/revoke/${keyId}`).then(({ data }) => data);
    }

    static getApiKeyKeyValue(keyId: number): Promise<any> {
        return ApiKeys.get(`${RESOURCE_URI}/key/${keyId}`).then(({ data }) => data);
    }

    static editApiKey(keyId: number, payload: ApiKeyPayload): Promise<any> {
        return ApiKeys.put(`${RESOURCE_URI}/${keyId}`, payload).then(({ data }) => data);
    }

    static createApiKey(payload: ApiKeyPayload): Promise<any> {
        return ApiKeys.post(`${RESOURCE_URI}`, payload).then(({ data }) => data);
    }
}

export default ApiKeys;
