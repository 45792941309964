import {
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Button, Dialog, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

type RemapConfirmationModalProps = {
    warningTextParams: {
        impactedBusinesses: string;
        categoryGmbId: string;
        selectedCategoryGmbId: string;
    };
    onSaveClick: () => void;
    onCancelClick: () => void;
};

export const RemapConfirmationModal = ({
    warningTextParams,
    onSaveClick,
    onCancelClick,
}: RemapConfirmationModalProps) => {
    const { t } = useTranslation();
    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name: IllustrationOldEnum.Warning,
    };
    return (
        <Dialog
            dataTrackId=""
            headerContent={illustrationElement}
            subTitle={t('admin_categories_obsolete_confirmationpopin_warning_plural', {
                count: parseInt(warningTextParams.impactedBusinesses),
                ...warningTextParams,
            })}
            title={t('admin_categories_obsolete_confirmationpopin_title')}
            actions={[
                <Button
                    dataTrackId="page_remap_category__confirmation_modal__cancel"
                    variant="secondary"
                    size="large"
                    shape="cube"
                    appearance="outlined"
                    onClick={() => onCancelClick()}
                    full
                >
                    <Text variant="bodyLBold">
                        {t('admin_categories_obsolete_confirmationpopin_cancel')}
                    </Text>
                </Button>,
                <Button
                    dataTrackId="page_remap_category__confirmation_modal__save"
                    onClick={onSaveClick}
                    variant="primary"
                    shape="cube"
                    size="large"
                    full
                >
                    {t('save')}
                </Button>,
            ]}
        />
    );
};
