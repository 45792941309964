import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const SectionGlobal = styled.div`
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    margin-bottom: 24px;
`;

export const SectionCase = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-bottom: solid 1px ${({ theme }) => theme.colors.default.alpha};
`;

export const SectionHeader = styled(SectionCase)`
    padding: 17px 24px;
`;

export const SectionFooter = styled(SectionCase)`
    border-bottom: 0px;
`;

export const SectionTitle = styled.div`
    display: flex;
    align-items: baseline;
    font-size: 18px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.default.initial};
`;

export const SectionSubtitle = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;

export const SectionHeaderMenu = styled.div`
    display: flex;
    align-items: baseline;
    min-width: fit-content;
`;

export const SectionFooterContent = styled.div`
    display: flex;
    align-items: baseline;
    font-size: 14px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary.initial};
`;

export const Suggestion = styled.div`
    margin: 8px 8px 0 0;
`;
