import { getQueryObjectFromObject } from '@partoohub/utils';

import { ReviewTagMatch } from 'app/api/types/interactionTag';
import { ReviewQueryParams, ReviewTagStat } from 'app/api/types/review';
import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

const RESOURCE_URI = 'review_tag';

class ReviewTag extends ApiResourceBaseClass {
    /* All interactions' API calls between tags and reviews, file .py in `web/controllers/api/v2/review_tag.py` */
    static async assign(body: ReviewTagMatch | ReviewTagMatch[]) {
        /* Assign tags to reviews, request `POST /api/v2/review_tag` */
        const { data } = await ReviewTag.post(`${RESOURCE_URI}/assign`, body);
        return data;
    }

    static async unassign(body: ReviewTagMatch | ReviewTagMatch[]) {
        /* Unassign tags from reviews, request `DELETE /api/v2/review_tag` */
        const { data } = await ReviewTag.post(`${RESOURCE_URI}/unassign`, body);
        return data;
    }

    static async status(queryParams: ReviewQueryParams): Promise<Array<ReviewTagStat>> {
        const { data } = await ReviewTag.get(
            `${RESOURCE_URI}/status`,
            getQueryObjectFromObject(queryParams),
        );
        return data.stats;
    }
}

export default ReviewTag;
