import { FC, useEffect, useState } from 'react';

import { MultiSelect, Option, Section, SingleSelect } from '@partoohub/ui';
import { useQuery } from 'react-query';

import type { SearchBusinessParams, V2BusinessData } from 'app/api/types/business';

import { businessApiClient } from 'admin/api/apiResources';
import { BUSINESSES } from 'admin/common/data/queryKeysConstants';
import useDebounce from 'admin/common/hooks/useDebounce';

const convertBusinessToSelectOption = (business: V2BusinessData) => ({
    label: `${business.name} (${business.id})`,
    name: business.id,
    value: business.id,
});

type CommonProps = {
    dataTrackId: string;
    placeholder: string;
    searchBusinessParams?: SearchBusinessParams;
    isMulti?: boolean;
    required?: boolean;
};

type MultiSelectProps = {
    businesses: Option[];
    setBusinesses: (businesses: Option[]) => void;
} & CommonProps;

type SingleSelectProps = {
    businesses?: Option;
    setBusinesses: (business?: Option) => void;
} & CommonProps;

type Props = MultiSelectProps | SingleSelectProps;

const BusinessSelect: FC<Props> = ({
    dataTrackId,
    placeholder,
    searchBusinessParams,
    isMulti = false,
    required,
    businesses,
    setBusinesses,
}: Props) => {
    const [businessSearch, setBusinessSearch] = useState('');
    const [section, setSection] = useState<Section[]>([]);

    const debouncedBusinessSearch = useDebounce(businessSearch);

    const { data, isLoading } = useQuery(
        [BUSINESSES, { ...searchBusinessParams, query: debouncedBusinessSearch }],
        ({ pageParam = 1 }) =>
            businessApiClient.searchBusinesses({
                ...(searchBusinessParams || {}),
                query: debouncedBusinessSearch,
                page: pageParam,
            }),
    );

    useEffect(() => {
        if (!isLoading) {
            const options =
                data?.businesses
                    ?.flat()
                    ?.map(business => convertBusinessToSelectOption(business)) || [];
            const section = [{ options: options }];
            setSection(section);
        }
    }, [data]);

    const handleMultiSelectChange = (selectedOptions: Option[]): void => {
        (setBusinesses as (businesses: Option[]) => void)(selectedOptions);
    };

    const handleSingleSelectChange = (selectedOption?: Option): void => {
        (setBusinesses as (business?: Option) => void)(selectedOption);
    };

    return (
        <>
            {isMulti ? (
                <MultiSelect
                    dataTrackId={dataTrackId}
                    placeholder={placeholder}
                    label={placeholder}
                    sections={section}
                    selectedValue={businesses as Option[]}
                    onChange={handleMultiSelectChange}
                    onSearch={setBusinessSearch}
                    maxHeight={300}
                    required={required}
                />
            ) : (
                <SingleSelect
                    dataTrackId={dataTrackId}
                    placeholder={placeholder}
                    label={placeholder}
                    sections={section}
                    // @ts-expect-error
                    selectedValue={businesses}
                    onChange={handleSingleSelectChange}
                    onSearch={setBusinessSearch}
                    maxHeight={300}
                    required={required}
                />
            )}
        </>
    );
};

export default BusinessSelect;
