import React, { FC, ReactNode } from 'react';

import { NavLink } from 'react-router';

import { SublinkChip, SublinkStyled, SublinkText } from './Sublink.styled';

type Props = {
    to: string;
    name: string;
    chipContent?: string | number | ReactNode;
    overrideIsActive?: () => boolean;
};

const Sublink: FC<Props> = ({ to, name, chipContent, overrideIsActive }: Props) => {
    const isLinkActive = (navLinkIsActive: boolean) =>
        overrideIsActive !== undefined ? overrideIsActive() : navLinkIsActive;

    return (
        <SublinkStyled>
            <NavLink
                to={to}
                className={({ isActive }) => (isLinkActive(isActive) ? 'link active' : 'link')}
            >
                <SublinkText>{name}</SublinkText>
                {chipContent !== undefined && (
                    <SublinkChip className="link_chip">{chipContent}</SublinkChip>
                )}
            </NavLink>
        </SublinkStyled>
    );
};

export default Sublink;
