import { ServiceModalFormType } from 'javascript/admin/src/content/data/pages/ServicesCreation/old/components/modals/AddServiceModal';

import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

export const servicesTypeEnum = {
    STRUCTURED_SERVICE_ITEM: 'STRUCTURED_SERVICE_ITEM',
    FREE_FORM_SERVICE_ITEM: 'FREE_FORM_SERVICE_ITEM',
};

// ## Services
// Get
export type ServiceType = {
    service_id: number;
    type: string;
    name: string;
    price: number;
    description: string;
    category_id: number;
    category_gmb_name: string;
    country: string;
};

export type ServicesGetDataType = {
    services: Array<ServiceType>;
};

export type ServicesGetErrorType = {
    response: {
        data: {
            errors: {
                authorization?: {
                    services: string;
                };
                json?: string;
            };
        };
        status: number;
    };
};

// Create
export const structuredServiceCreateQueryParams = (
    serviceId: number,
    description?: string,
    price?: string,
) =>
    ({
        service_id: serviceId,
        description: description || null,
        price: !Number.isNaN(parseFloat(price!)) ? parseFloat(price!) : null,
    }) as StructuredServiceCreateParamsType;

export type StructuredServiceCreateParamsType = {
    service_id: number;
    price?: number | null;
    description?: string;
};

export const freeFormServiceCreateQueryParams = (
    categoryGmbName: string,
    name: string,
    description?: string,
    price?: string,
) =>
    ({
        category_gmb_name: categoryGmbName,
        name,
        description: description || null,
        price: !Number.isNaN(parseFloat(price!)) ? parseFloat(price!) : null,
    }) as FreeFormServiceCreateParamsType;

export type FreeFormServiceCreateParamsType = {
    category_gmb_name: string;
    name: string;
    price?: number | null;
    description?: string;
};

export const adminServiceCreateQueryParams = (
    categoryGmbName: string,
    name: string,
    type: string,
    description?: string,
    price?: string,
    serviceId?: number,
) =>
    ({
        category_gmb_name: categoryGmbName,
        name,
        description: description || null,
        price: !Number.isNaN(parseFloat(price!)) ? parseFloat(price!) : null,
        service_id: serviceId || null,
        type,
    }) as AdminServiceCreateParamsType;

export const adminBulkCreateServiceQueryParams = (
    categoryGmbName: string,
    services: Array<ServiceModalFormType>,
    isOverrideChecked: boolean,
) => ({
    services: services.map(service =>
        adminServiceCreateQueryParams(
            categoryGmbName,
            service.name,
            service.type,
            service.description,
            service.price,
            service.serviceId,
        ),
    ),
    is_override_checked: isOverrideChecked,
});

export type AdminServiceCreateParamsType = {
    category_gmb_name: string;
    name: string;
    type: string;
    price?: number | null;
    description?: string | null;
    service_id?: number | null;
};

export type AdminBulkCreateServiceParamsType = BusinessModalFiltersType & {
    services: Array<AdminServiceCreateParamsType>;
    is_override_checked: boolean;
};

// Update
export const structuredServiceUpdateQueryParams = (
    serviceId: number,
    description?: string,
    price?: string,
) =>
    ({
        service_id: serviceId,
        description: description || null,
        price: !Number.isNaN(parseFloat(price!)) ? parseFloat(price!) : null,
    }) as StructuredServiceUpdateParamsType;

export type StructuredServiceUpdateParamsType = {
    service_id: number;
    price?: number | null;
    description?: string;
};

export const freeFormServiceUpdateQueryParams = (
    serviceId: number,
    name?: string,
    description?: string,
    price?: string,
) =>
    ({
        service_id: serviceId,
        name: name || null,
        description: description || null,
        price: !Number.isNaN(parseFloat(price!)) ? parseFloat(price!) : null,
    }) as FreeFormServiceUpdateParamsType;

export type FreeFormServiceUpdateParamsType = {
    service_id: number;
    name?: string;
    price?: number | null;
    description?: string;
};

// Get suggestions
export type ServiceSuggestionGetParamsType = {
    business_id: string;
};

export type ServiceSuggestionType = {
    service_id: number;
    type: string;
    name: string;
    language: string;
    country: string;
    category_id: number;
    category_gmb_name: string;
};

export type ServicesSuggestionsGetDataType = {
    services_suggestions: Array<ServiceSuggestionType>;
};
