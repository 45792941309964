/* Path tokens
 */

// Auth
export const CONNECTION = 'connection';
export const UNAUTHORIZED = 'unauthorized';

// Connect as
export const CONNECT_AS = 'connect-as';

// Data
export const DATA = 'data';
export const DATA_EXPORTER = 'exporter';
export const DATA_OLD_EXPORTER = 'old-exporter';
export const DATA_MESSAGES_EXPORTER = 'messages/exporter';
export const DATA_IMPORTER = 'importer';
export const DATA_OLD_IMPORTER = 'old-importer';
export const DATA_MESSAGES_IMPORTER = 'messages/importer';
export const DATA_TRIPADVISOR_IMPORTER = 'tripadvisor/importer';
export const BULK_SERVICES = 'services';
export const BULK_FOOD_MENUS = 'food-menus';
export const IMAGE_UPLOADER = 'image-uploader';

// Logs
export const LOGS = 'logs';
export const LOGS_DIFFUSION = 'diffusion';
export const IMPORT_LOGS = 'import-logs';
export const DAILY_REPORTS = 'daily-reports';

// Categories
export const CATEGORIES = 'categories';
export const CATEGORIES_EDIT = 'edit';
export const CATEGORIES_REMAP = 'remap';

// Tools
export const TOOLS = 'tools';
export const TOOLS_OVERRIDING = 'overriding';
export const TOOLS_REVIEW = 'review';
export const TOOLS_SEARCH_LOCATION = 'search/location';
export const SEARCH_LOCATION_PROSPECTS = 'prospects';
export const SEARCH_LOCATION_CLIENTS = 'clients';
export const TOOLS_GENERATE_FAKE_DATA = 'generate-fake-data';
export const TOOLS_DEMO_CREATOR = 'demo-creator';
export const TOOLS_DIFFUSION_CLEANER = 'diffusion-cleaner';
export const LOCATIONS = 'locations';
export const PLATFORMS = 'platforms';
export const SETTINGS = 'settings';
export const VISIBILITY = 'visibility';
export const TOOLS_FEEDBACK_MANAGEMENT = 'feedback-management';
export const ORGANIZATION_SETTINGS = 'organization-settings';
export const GENERIC_PROMPT = 'generic-prompt';
export const TOOLS_COMPETITIVE_BENCHMARK = 'competitive-benchmark';

// Playground
export const PLAYGROUND = 'playground';
export const PLAYGROUND_GOOGLE_ACCOUNT_REFRESH = 'account-refresh';
export const PLAYGROUND_REVIEW_CLEANER = 'review-cleaner';
export const PLAYGROUND_USER_ACTIONS = 'user-actions';
export const PLAYGROUND_CALL_DEFLECTION = 'call-deflection';
export const PLAYGROUND_GOOGLE_ADMIN_MANAGEMENT = 'google-admin-management';

/* Paths
 */
// Auth
export const CONNECTION_PATH = `/${CONNECTION}`;
export const UNAUTHORIZED_PATH = `/${UNAUTHORIZED}`;

// Connect as
export const CONNECT_AS_PATH = `/${CONNECT_AS}`;

// Data
export const DATA_PATH = `/${DATA}`;
export const EXPORTER_PATH = `/${DATA}/${DATA_EXPORTER}`;
export const OLD_EXPORTER_PATH = `/${DATA}/${DATA_OLD_EXPORTER}`;
export const IMPORTER_PATH = `/${DATA}/${DATA_IMPORTER}`;
export const OLD_IMPORTER_PATH = `/${DATA}/${DATA_OLD_IMPORTER}`;
export const TRIPADVISOR_IMPORTER_PATH = `/${DATA}/${DATA_TRIPADVISOR_IMPORTER}`;
export const SERVICE_CREATION_PATH = `/${DATA}/${BULK_SERVICES}`;
export const MENU_CREATION_PATH = `/${DATA}/${BULK_FOOD_MENUS}`;
export const IMAGE_UPLOADER_PATH = `/${DATA}/${IMAGE_UPLOADER}`;

// Logs
export const LOGS_PATH = `/${LOGS}`;
export const DIFFUSION_LOGS_PATH = `/${LOGS}/${LOGS_DIFFUSION}`;
export const IMPORT_LOGS_PATH = `/${LOGS}/${IMPORT_LOGS}`;
export const DAILY_REPORTS_PATH = `/${LOGS}/${DAILY_REPORTS}`;

// Categories
export const CATEGORIES_PATH = `/${CATEGORIES}`;
export const CATEGORIES_EDIT_PATH = `/${CATEGORIES}/${CATEGORIES_EDIT}`;
export const CATEGORIES_REMAP_PATH = `/${CATEGORIES}/${CATEGORIES_REMAP}`;

// Tools
export const TOOLS_PATH = `/${TOOLS}`;
export const OVERRIDING_TOOL_PATH = `/${TOOLS}/${TOOLS_OVERRIDING}`;
export const REVIEW_TOOL_PATH = `/${TOOLS}/${TOOLS_REVIEW}`;
export const PROSPECTS_LOCATION_SEARCH_TOOL_PATH = `/${TOOLS}/${TOOLS_SEARCH_LOCATION}/${SEARCH_LOCATION_PROSPECTS}`;
export const DUPLICATES_CLIENT_LOCATION_SEARCH_TOOL_PATH = `/${TOOLS}/${TOOLS_SEARCH_LOCATION}/${SEARCH_LOCATION_CLIENTS}`;
export const GENERATE_FAKE_DATA_PATH = `/${TOOLS}/${TOOLS_GENERATE_FAKE_DATA}`;
export const DEMO_CREATOR_PATH = `/${TOOLS}/${TOOLS_DEMO_CREATOR}`;
export const DIFFUSION_CLEANER_PATH = `/${TOOLS}/${TOOLS_DIFFUSION_CLEANER}`;
export const VISIBILITY_LOCATION_PATH = `/${VISIBILITY}/${LOCATIONS}`;
export const FEEDBACK_MANAGEMENT_ORGANIZATION_SETTINGS_PATH = `/${TOOLS}/${TOOLS_FEEDBACK_MANAGEMENT}/${ORGANIZATION_SETTINGS}`;
export const FEEDBACK_MANAGEMENT_GENERIC_PROMPT_PATH = `/${TOOLS}/${TOOLS_FEEDBACK_MANAGEMENT}/${GENERIC_PROMPT}`;
export const COMPETITIVE_BENCHMARK_PATH = `/${TOOLS}/${TOOLS_COMPETITIVE_BENCHMARK}`;

// Playground
export const PLAYGROUND_PATH = `/${PLAYGROUND}`;
export const GOOGLE_ACCOUNT_REFRESH_PATH = `/${PLAYGROUND}/${PLAYGROUND_GOOGLE_ACCOUNT_REFRESH}`;
export const REVIEW_CLEANER_PATH = `/${PLAYGROUND}/${PLAYGROUND_REVIEW_CLEANER}`;
export const USER_ACTIONS_PATH = `/${PLAYGROUND}/${PLAYGROUND_USER_ACTIONS}`;
export const CALL_DEFLECTION_PATH = `/${PLAYGROUND}/${PLAYGROUND_CALL_DEFLECTION}`;
export const GOOGLE_ADMIN_MANAGEMENT_PATH = `/${PLAYGROUND}/${PLAYGROUND_GOOGLE_ADMIN_MANAGEMENT}`;
