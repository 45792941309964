import ConversationStarters from 'app/api/v2/api_calls/conversationStartersApiCall';
import { CustomFieldsSections } from 'app/api/v2/api_calls/customFieldsSectionsApiCalls';
import GoogleAdminManagement from 'app/api/v2/api_calls/googleAdminManagementApiCalls';
import Holidays from 'app/api/v2/api_calls/holidaysApiCalls';
import Invoice from 'app/api/v2/api_calls/invoiceApiCalls';
import { Prospects } from 'app/api/v2/api_calls/prospectsApiCalls';
import PublisherErrors from 'app/api/v2/api_calls/publisherErrorsApiCalls';
import Services from 'app/api/v2/api_calls/servicesApiCalls';

import Account from './accountApiCalls';
import Address from './addressApiCalls';
import Analytics from './analyticsApiCalls';
import ApiKeys from './apiKeysApiCalls';
import Attributes from './attributesApiCalls';
import {
    BulkExportAdminApiCall as BulkExportAdmin,
    BulkExportClientApiCall as BulkExportClient,
} from './bulkExportApiCalls';
import BulkImportAdmin, { BulkImportClientApiCall as BulkImportClient } from './bulkImportApiCalls';
import BusinessModule from './business';
import Business from './businessApiCalls';
import Categories from './categoriesApiCalls';
import Comments from './commentsApiCalls';
import CompetitiveBenchmark from './competitiveBenchmark';
import Connection from './connectionApiCalls';
import CustomFields from './customFieldsApiCalls';
import DiffusionErrors from './diffusionErrorsApiCalls';
import { FeedbackForm } from './feedbackForm';
import { FeedbackLookerStudio } from './feedbackLookerStudio';
import { FeedbackReplySuggestion } from './feedbackReplySuggestionApiCalls';
import { FeedbackResults } from './feedbackResultsApiCalls';
import Geocoding from './geocodingApiCalls';
import GroupV2 from './groupsV2ApiCalls';
import Image from './imageApiCalls';
import InteractionTag from './interactionTagApiCalls';
import { Leads } from './leadsApiCalls';
import Messaging from './messagingApiCalls';
import MessagingCallDeflection from './messagingCallDeflectionApiCalls';
import MessagingReplyTemplates from './messagingReplyTemplateApiCalls';
import MessagingSettings from './messagingSettingsApiCalls';
import MessagingStats from './messagingStatsApiCalls';
import { MessagingWidgetSetup } from './messagingWidgetSetupApiCalls';
import MoreHours from './moreHoursApiCalls';
import Organization from './orgApiCalls';
import PartnerLink from './partnerLinksApiCalls';
import PlaceActionLinks from './placeActionLinksApiCalls';
import Post from './postApiCalls';
import PresenceAnalytics from './presenceAnalyticsApiCalls';
import Publisher from './publisherApiCalls';
import PublisherState from './publisherStateApiCalls';
import { ReviewAnalytics } from './reviewAnalyticsApiCalls';
import Review from './reviewApiCalls';
import { ReviewAutoTagSettings } from './reviewAutoTagSettingsApiCalls';
import ReviewBooster from './reviewBoosterApiCalls';
import ReviewTag from './reviewTagApiCalls';
import Scopes from './scopesApiCalls';
import Subscription from './subscriptionApiCalls';
import User from './userApiCalls';

const api = {
    reviewBooster: ReviewBooster,
    connection: Connection,
    review: Review,
    reviewAnalytics: ReviewAnalytics,
    reviewAutoTagSettings: ReviewAutoTagSettings,
    feedbackForm: FeedbackForm,
    feedbackLookerStudio: FeedbackLookerStudio,
    feedbackResults: FeedbackResults,
    feedbackReplySuggestion: FeedbackReplySuggestion,
    user: User,
    address: Address,
    account: Account,
    business: Business,
    businessModule: BusinessModule,
    diffusionErrors: DiffusionErrors,
    org: Organization,
    publisherErrors: PublisherErrors,
    presenceAnalytics: PresenceAnalytics,
    comments: Comments,
    groupsV2: GroupV2,
    image: Image,
    publisher: Publisher,
    publisherState: PublisherState,
    analytics: Analytics,
    competitiveBenchmark: CompetitiveBenchmark,
    categories: Categories,
    geocoding: Geocoding,
    post: Post,
    customFields: CustomFields,
    customFieldsSections: CustomFieldsSections,
    attributes: Attributes,
    moreHours: MoreHours,
    apiKeys: ApiKeys,
    messaging: Messaging,
    leads: Leads,
    prospects: Prospects,
    subscription: Subscription,
    bulkExportAdmin: BulkExportAdmin,
    bulkExportClient: BulkExportClient,
    bulkImportAdmin: BulkImportAdmin,
    bulkImportClient: BulkImportClient,
    services: Services,
    scopes: Scopes,
    messagingStats: MessagingStats,
    messagingSettings: MessagingSettings,
    messagingReplyTemplates: MessagingReplyTemplates,
    MessagingCallDeflection: MessagingCallDeflection,
    messagingWidgetSetup: MessagingWidgetSetup,
    interactionTag: InteractionTag,
    reviewTag: ReviewTag,
    conversationStarters: ConversationStarters,
    invoice: Invoice,
    holidays: Holidays,
    googleAdminManagement: GoogleAdminManagement,
    placeActionLinks: PlaceActionLinks,
    partnerLinks: PartnerLink,
};

export default api;
