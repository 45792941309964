import { getQueryStringFromObject } from '@partoohub/utils';

import { SuccessResponse } from 'app/api/types';
import {
    CategoriesData,
    CategoriesFilter,
    CategoriesOnPublishers,
    CategoriesStats,
    CategoryData,
    CategoryDetailData,
    CategoryDetailFilter,
    CategoryOnPublisherFilter,
    EditCategoryPayload,
    RemapCategoryPayload,
    SuggestedCategoriesData,
} from 'app/api/types/categories';
import { generateQueryParamsString } from 'app/api/v2/api_calls/utils';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'categories';

class Categories extends ApiResourceBaseClass {
    static getCategoriesDetails(
        queryFilter: CategoriesFilter = {},
        page = 1,
    ): Promise<CategoriesData> {
        const queryParams = getQueryStringFromObject({ ...queryFilter, page });
        return Categories.get(`${RESOURCE_URI}/details?${queryParams}`).then(({ data }) => data);
    }

    static getCategoryDetails(
        categoryId: number,
        queryFilter: CategoryDetailFilter = {},
    ): Promise<CategoryDetailData> {
        const queryParams = getQueryStringFromObject({ ...queryFilter });
        return Categories.get(`${RESOURCE_URI}/${categoryId}/details?${queryParams}`).then(
            ({ data }) => data,
        );
    }

    static getCategory(categoryId: string, lang: string): Promise<CategoryData> {
        const queryParams: CategoryDetailFilter = {
            lang,
            return_live: true,
        };
        return Categories.get(`${RESOURCE_URI}/${categoryId}`, queryParams).then(
            ({ data }) => data,
        );
    }

    static getCategories(
        query: string,
        country: string | null,
        lang: string | null,
    ): Promise<CategoriesData> {
        const queryParams: CategoriesFilter = {
            country: country || null,
            lang: lang || null,
            search_query: query,
            live__isnull: true,
            state: 'OK',
            order_by: '-impacted_businesses',
        };
        return Categories.get(`${RESOURCE_URI}`, queryParams).then(({ data }) => data);
    }

    static getSuggestedCategories(
        categoryId: string,
        lang: string | null = null,
    ): Promise<SuggestedCategoriesData> {
        const queryParams = { lang };
        return Categories.get(`${RESOURCE_URI}/${categoryId}/suggestions`, queryParams).then(
            ({ data }) => data,
        );
    }

    static editCategory(
        categoryId: number,
        payload: EditCategoryPayload,
    ): Promise<CategoryDetailData> {
        return Categories.put(`${RESOURCE_URI}/${categoryId}/details`, payload).then(
            ({ data }) => data,
        );
    }

    static getCategoriesOnPublishers(
        queryFilter: CategoryOnPublisherFilter = {},
        page = 1,
    ): Promise<CategoriesOnPublishers> {
        const queryParams = getQueryStringFromObject({ ...queryFilter, page });
        return Categories.get(`publishers_categories?${queryParams}`).then(({ data }) => data);
    }

    static postCategoriesRemap(categoriesToRemap: RemapCategoryPayload): Promise<SuccessResponse> {
        return Categories.post(`${RESOURCE_URI}/remap`, categoriesToRemap).then(({ data }) => data);
    }

    static exportCategories(queryParams: CategoriesFilter): Promise<any> {
        return Categories.get(`${RESOURCE_URI}/export?${generateQueryParamsString(queryParams)}`);
    }

    static getCategoriesStats(): Promise<CategoriesStats> {
        return Categories.get(`${RESOURCE_URI}/stats`).then(({ data }) => data);
    }
}

export default Categories;
