import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import { FeedbackLookerStudioData } from 'app/api/types/feedbackLookerStudio';
import api from 'app/api/v2/api_calls';

import { FEEDBACK_LOOKER_STUDIO } from 'admin/common/data/queryKeysConstants';

export const useGetFeedbackLookerStudio = (
    orgId: number,
    options: UseQueryOptions<FeedbackLookerStudioData> = {},
) => {
    const query = useQuery(
        [FEEDBACK_LOOKER_STUDIO, orgId] as QueryKey,
        () => api.feedbackLookerStudio.getFeedbackLookerStudio(orgId),
        {
            ...options,
            retry: false,
        },
    );

    const lookerStudio = query?.data;

    return lookerStudio;
};
